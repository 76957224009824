import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState, useContext } from 'react';
import config from '../config';
import { AppContext } from '../AppContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e8e8e8",
    display: "flex",
    justifyContent: "center"
  },
  title: {
    fontFamily: "Lobster",
    color: "black",
    marginBottom: "8px"
  },
  img: {
    height: props => props.isMobile ? "60px" : "80px"
  },
  texts: {
    marginTop: "auto",
    marginBottom: "auto",
  }
}));
const getName = () => {
  let names = config.images;
  return names[Math.floor(Math.random() * names.length)]
}

export const TopLogo = () => {
  const [image, setImage] = useState(`/images/${getName()}`);
  const {isMobile} = useContext(AppContext);
  const history = useHistory();

  const classes = useStyles({isMobile});

  return (
    <div className={classes.root}>
      <div>
        <img src={image} className={classes.img}></img>
      </div>
      <div className={classes.texts}>
        <Typography variant={isMobile ? "h4" : "h3"} className={classes.title}>
          Magnificently
      </Typography>
        <center>
          <Typography variant={isMobile ? "subtitle1" : "h5"} style={{ marginTop: "-10px", marginLeft: "-20px" }}>
            Draw with friends
      </Typography>
        </center>
      </div>

    </div>
  )
}
