import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState, useEffect } from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { AppContext } from '../AppContext';
import { useStore } from '../store';
import { ColorPicker } from "./ColorPicker";
import { SizePicker } from './SizePicker';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    position: "relative",
    padding: theme.spacing(1),
    backgroundColor: "#e8e8e8",
    width: props =>  props.isMobile ? "60px" : "70px",
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    zIndex: 1000
  }, btn: {
    background: "white",
    '&:hover': {
      background: ( (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) || window.safari !== undefined ) ? "white" : "#808080"
    },
    position: "relative"
  },
  circular: {
    width: props => props.size * 1.2, height: props => props.size * 1.2, background: props => props.color, borderRadius: 20
  },
  img: {
    width: props => !props.isMobile ? 25 : 15
  },
  innerBtn: {
    width: props => !props.isMobile ? 25 : 15,
    height: props => !props.isMobile ? 25 : 15
  },
  secondBtn: {
    width: props => !props.isMobile ? 25 : 15,
    height: props => !props.isMobile ? 25 : 15
  }
}));

export default function Icons() {
  const [widget, setWidget] = useState(false);
  const [widget3, setWidget3] = useState(false);
  const [sizeWidget, setSizeWidget] = useState(false);
  const { gameProps, socket, setGameProps, roomId, addToGameProp, color, size } = useStore(state => ({ gameProps: state.gameProps, setGameProps: state.setGameProps, refresh: !state.refresh, socket: state.socket, roomId: state.roomId, addToGameProp: state.addToGameProp, color: state.color, size: state.size }))
  const { isMobile } = useContext(AppContext)
  const classes = useStyles({ size, color, isMobile });

  useEffect(() => {

  }, [])

  const clear = () => {
    if (gameProps.curGame === "drawing") {
      socket.emit("setGameProps", {
        room: roomId,
        values: {
          drawing: []
        }
      })
      setGameProps({ values: { drawing: [] } })
    } else if (gameProps.curGame === "squiggle") {
      const userIndex = gameProps.participants.findIndex(s => s === socket.id);
      const drawingIndex = (gameProps.index + userIndex) % gameProps.participants.length;
      socket.emit("addToGameProp", {
        keys: ["squiggleDrawings", drawingIndex, gameProps.index],
        value: "clear",
        room: roomId
      })
      addToGameProp({ keys: ["squiggleDrawings", drawingIndex, gameProps.index], value: "clear" })
    } else {
      const userIndex = gameProps.participants.findIndex(s => s === socket.id);
      const drawingIndex = (gameProps.index + userIndex) % gameProps.participants.length;
      socket.emit("addToGameProp", {
        keys: ["corpseDrawings", drawingIndex, gameProps.index],
        value: "clear",
        room: roomId
      })
      addToGameProp({ keys: ["corpseDrawings", drawingIndex, gameProps.index], value: "clear" })
    }
  }

  const undo = () => {
    if (gameProps.curGame === "drawing") {
      socket.emit("addToGameProp", {
        keys: ["drawing"],
        value: "undo",
        room: roomId
      })
      addToGameProp({ keys: ["drawing"], value: "undo" })
    } else if (gameProps.curGame === "squiggle") {
      const userIndex = gameProps.participants.findIndex(s => s === socket.id);
      const drawingIndex = (gameProps.index + userIndex) % gameProps.participants.length;
      socket.emit("addToGameProp", {
        keys: ["squiggleDrawings", drawingIndex, gameProps.index],
        value: "undo",
        room: roomId
      })
      addToGameProp({ keys: ["squiggleDrawings", drawingIndex, gameProps.index], value: "undo" })
    } else {
      const userIndex = gameProps.participants.findIndex(s => s === socket.id);
      const drawingIndex = (gameProps.index + userIndex) % gameProps.participants.length;
      socket.emit("addToGameProp", {
        keys: ["corpseDrawings", drawingIndex, gameProps.index],
        value: "undo",
        room: roomId
      })
      addToGameProp({ keys: ["corpseDrawings", drawingIndex, gameProps.index], value: "undo" })
    }
  }

  return (
    <div>
    <div className={classes.root}>
      <div>
        <ClickAwayListener onClickAway={() => {
          setWidget(false)
        }}>
          <div>
        <div>
          <Tooltip
  disabled={window.mobileAndTabletCheck()}
            // options
            title="Brush color"
            position="right"
          >
            <IconButton onClick={() => {
              setWidget(!widget)
              setWidget3(false);
              setSizeWidget(false);
            }} variant="contained" aria-label="delete" style={{ background: color }}>
              <span className={classes.innerBtn}></span>
            </IconButton>
          </Tooltip>
        </div>
        {widget && <div style={{ position: "fixed" }}> <ColorPicker label={"color"} /> </div>}
        </div>
        </ClickAwayListener>
      </div>

      <div>
      <ClickAwayListener onClickAway={() => {
          setSizeWidget(false)
        }}>
          <div>
        <Tooltip
  disabled={window.mobileAndTabletCheck()}
          // options
          title="Brush size"
          position="right"
        >
          <IconButton onClick={(e) => {
            e.preventDefault();
            setSizeWidget(!sizeWidget)
            setWidget3(false);
            setWidget(false);
          }} aria-label="delete" style={{ width: !isMobile ? 48 : 40, height: !isMobile ? 48 : 40, background: "white" }} >
            <div className={classes.circular}> </div>

            {/* <span style={{width: 15, height: 15, background: color, borderRadius: 20, marginLeft: 1}}></span> */}
          </IconButton>
        </Tooltip>
        {sizeWidget && <div style={{ position: "fixed" }}> <SizePicker /> </div>}
        </div>
        </ClickAwayListener>
      </div>

      <div>
      <ClickAwayListener onClickAway={() => {
          setWidget3(false)
        }}>
          <div>
        <Tooltip
  disabled={window.mobileAndTabletCheck()}
          // options
          title="Background color"
          position="right"
        >
          <IconButton color="secondary" onClick={() => {
            setWidget3(!widget3)
            setSizeWidget(false)
            setWidget(false);
          }} aria-label="add an alarm" className={classes.btn}>
            <div style={{width: isMobile ? 15 : 25, height: isMobile ? 15 : 25, border: "1px solid black", background: gameProps.backgroundColor}}></div>
          </IconButton>
        </Tooltip>
        {widget3 && <div style={{ position: "fixed" }}> <ColorPicker label={"backgroundColor"} /> </div>}
        </div>
        </ClickAwayListener>
      </div>
      {/* <div>
            <Tooltip
  disabled={window.mobileAndTabletCheck()}
              // options
              title="Clear background"
              position="right"
            >
            <IconButton onClick={() => {
              socket.emit("setGameProps", {
                room: roomId,
                values: {
                  backgroundColor: "white"
                }
              })
              setGameProps({values: {
                backgroundColor: "#fff"
              }})
            }}  variant="contained" aria-label="delete" className={classes.btn}> 
              <img src={"/icon2.png"} className={classes.img} />
            </IconButton>
            </Tooltip>
        </div> */}

      <div>

        <Tooltip
  disabled={window.mobileAndTabletCheck()}
          // options
          title="Undo last stroke"
          position="right"
        >
          <IconButton color="secondary" aria-label="add an alarm" onClick={undo} className={classes.btn}>
            <img src={"/icon4.png"} className={classes.img} />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <Tooltip
  disabled={window.mobileAndTabletCheck()}
          // options
          title="Clear canvas"
          position="right"
        >
          <IconButton aria-label="delete" className={classes.btn} onClick={clear}>
            <img src={"/icon3.png"} className={classes.img} />
          </IconButton>
        </Tooltip>
      </div>

    </div>
    {isMobile && gameProps.curGame==="corpse" && <img style={{marginLeft: "5vw", marginTop: "5vw", position: "relative", zIndex: 1000}} src={"/corpse.png"} width="100px" />}
    </div>
  );
}