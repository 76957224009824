import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { useStore } from '../store';
import Ads from './utils/Ads';
import { SecondaryButton } from './utils/Buttons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e8e8e8",
    height: "40px",
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px"
  },
  title: {
    fontFamily: "Lobster"
  },
  subhead: {
    textAlign: 'left',
    color: "black",
    marginLeft: "20px",
    paddingTop: "6px"
  }
}));

export default function Header() {
  const { users, setGameProps, roomId, socket, gameProps } = useStore(state => ({
    users: state.users,
    setGameProps: state.setGameProps,
    users: state.users,
    roomId: state.roomId,
    socket: state.socket,
    gameProps: state.gameProps
  }))
  const { isMobile, width } = useContext(AppContext)
  const classes = useStyles({ isMobile });
  const history = useHistory();

  const startDrawing = () => {
    socket.emit("setGameProps", {
      room: roomId,
      values: {
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        curGame: "drawing",
        index: 0,
        squiggleEnded: false,
      }
    })
    setGameProps({
      values: {
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        curGame: "drawing",
        index: 0,
        squiggleEnded: false,
      }
    })
  }

  return (
    <div>
      <div className={classes.root}>
        <Typography variant={isMobile ? "h5" : "h5"} className={classes.title} onClick={() => history.push("/")}>
          Magnificently
          </Typography>


        <Typography style={{ marginTop: isMobile && gameProps.curGame === "corpse" && width < 400 ? "-12px" : "0px" }} socket={socket} variant={isMobile ? "subtitle2" : "subtitle1"} className={classes.subhead}>
          <b>
            {gameProps.curGame === "squiggle" && "Squiggle"}
            {gameProps.curGame === "corpse" && "Heads & Bodies"}
            {gameProps.curGame === "drawing" && "Lobby"}
          </b>
        </Typography>
        {gameProps.curGame !== "drawing" && <div style={{ marginLeft: "10%", marginTop: "-5px" }}> <SecondaryButton size="small" style={{ fontSize: isMobile ? "14px" : "" }} size="small" variant="contained" color="secondary" onClick={startDrawing}>
          <div style={{ marginLeft: "14px", marginRight: "14px" }}> Lobby</div>
        </SecondaryButton> </div>}
        <Ads />
      </div>
    </div>
  );
}