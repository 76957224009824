import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, {useContext} from 'react';
import { AppContext } from '../../AppContext';

const useStyles = makeStyles((theme) => ({
  primary: {
    marginLeft: props => props.isMobile ? 5 : 5,
    marginRight: props => props.isMobile ? 5 : 5,
    marginTop: 2,
    borderRadius: 30,
    marginBottom: 5,
    backgroundColor: "#64c8c8",
    color: "white",
    fontSize: 18,
    align: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: '#15a9a8'
    }
  },

  secondary: {
    marginLeft: props => props.isMobile ? 1 : 5,
    marginRight: props => props.isMobile ? 1 : 5,
    marginTop: 2,
    borderRadius: 30,
    backgroundColor: "white",
    color: "black",
    fontSize: 18,
    borderRadius: 20,
    paddingLeft: 10,
    paddingRight: 10,
    align: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: '#a6e6e6'
    }
  },

  tertiary: {
    marginLeft: props => props.isMobile ? 1 : 5,
    marginRight: props => props.isMobile ? 1 : 5,
    marginTop: 2,
    borderRadius: 20,
    color: "black",
    backgroundColor: "#e8e8e8",
    textTransform: "none",
    "&:hover": {
      backgroundColor: '#a6e6e6'
    },
  }

}));

export const PrimaryButton = (props) => {
  const {isMobile} = useContext(AppContext)
  const classes = useStyles({isMobile, padded : props.padded})
  return (
    <Button style={props.style} id={props.id} disabled={props.disabled || false} startIcon={props.startIcon} disableElevation size={props.size || "large"} variant="contained" className={classes.primary} onClick={props.onClick}>
      <span style={{paddingLeft: props.padded ? (props.isMobile ? 5 : 12) : (props.isMobile ? 1 : 5), paddingRight: props.padded ? (props.isMobile ? 5 : 12) : (props.isMobile ? 1 : 5) }}>
      {props.children}
      </span>
    </Button>
  )
}

export const SecondaryButton = (props) => {
  const classes = useStyles()
  return (
    <Button style={props.style} id={props.id} disabled={props.disabled || false} startIcon={props.startIcon} disableElevation size={props.size || "large"} variant="contained" className={classes.secondary} onClick={props.onClick}>
      {props.children}
    </Button>
  )
}

export const TertiaryButton = (props) => {
  const classes = useStyles()
  return (
    <Button disabled={props.disabled || false} startIcon={props.startIcon} disableElevation size={props.size || "large"} variant="contained" className={classes.tertiary} onClick={props.onClick}>
      {props.children}
    </Button>
  )
}

