import React from "react"
import { HuePicker } from "react-color";
import { useStore } from "../store";
import { makeStyles } from "@material-ui/core";

import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  topper: {
    background: "gray",
    position: "relative",
    left: 60,
    top: -50,
    width: 180,
    borderRadius: 5
  },
  sub: {
    marginLeft: 15,
    marginTop: -10,
    width: 150
  },
  spn: {
    background: "black", width: "30px", height: "20px", borderRadius: 5,
    position: "absolute",
    marginTop: -18,
    left: 225,
    cursor: "pointer"
  },
  circular: {
    width: props => props.size * 1.2,height:props => props.size * 1.2,background: props => props.color,borderRadius: 20
  }
}));

export const SizePicker = ( { label }) => {
  const {setSize, size, color} = useStore(state => ({setSize: state.setSize, size: state.size, color: state.color}))
  const classes = useStyles({size, color});

  return (
    <div className={classes.topper}>
      <div className={classes.sub}>
      <br/>
      <center>
      <div className={classes.circular}> </div>
      </center>
        <Slider
          value={size}
          getAriaValueText={""}
          aria-labelledby="discrete-slider-small-steps"
          step={1}
          marks
          min={1}
          max={20}
          valueLabelDisplay="auto"
          onChange={(e, val) => {
            setSize(val)
          }}
        />
      <br/>
      </div>
    </div>
  )
}
