import { makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  field: {
    fontSize: 16,
    border: "1px solid silver",
    borderRadius: "10",
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center"
  },

}))
export const CentralizedTextField = (props) => {
  const classes = useStyles();

  return (
    <div>
      <input
        {...props}
        className={classes.field}
      ></input>
    </div>
  )
}
