import React from "react"
import { HuePicker } from "react-color";
import { useStore } from "../store";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  topper: {
    background: "gray",
    position: "relative",
    left: 60,
    top: -50,
    width: 280,
    borderRadius: 5
  },
  sub: {
    marginLeft: 20
  },
  spn: {
    background: "black", width: "30px", height: "20px", borderRadius: 5,
    position: "absolute",
    marginTop: -18,
    left: 225,
    cursor: "pointer"
  },
  spnWhite: {
    background: "white", width: "30px", height: "20px", borderRadius: 5,
    position: "absolute",
    marginTop: -18,
    left: 225,
    cursor: "pointer"
  }
}));

export const ColorPicker = ( { label }) => {
  const {roomId, setGameProps, setColor, color, gameProps, setBackgroundColor, socket, backgroundColor} = useStore(state => ({setColor: state.setColor, color: state.color, setBackgroundColor: state.setBackgroundColor, backgroundColor: state.backgroundColor, socket: state.socket, roomId: state.roomId, setGameProps: state.setGameProps, backgroundColor: state.backgroundColor, gameProps: state.gameProps}))
  const classes = useStyles();
  return (
    <div className={classes.topper}>
      <br/>
      <div className={classes.sub}>
        <HuePicker
          style={{ width: "50px", zIndex: 2, position: "relative" }}
          onChange={(color) => {
            if (label === "color")
              setColor(color.hex);
            else {
              socket.emit("setGameProps", {
                room: roomId,
                values: {
                  backgroundColor: color.hex
                }
              })
              setGameProps({
                backgroundColor: color.hex
              })
            }
          }}  
          width={"200px"}
          onChangeComplete={(color) => {
            if (label === "color")
              setColor(color.hex);
            else {
              socket.emit("setGameProps", {
                room: roomId,
                values: {
                  backgroundColor: color.hex
                }
              })
              setGameProps({
                values: {
                  backgroundColor: color.hex
                }
              })
            }
          }}
          onAccept={() => { }}
          color={label === "color" ? color : gameProps.backgroundColor}
        />
        {label === "color" && <span className={classes.spn} onClick={() => {
          setColor("black");
        }}></span>}
        {label !== "color" && <span className={classes.spnWhite} onClick={() => {
          socket.emit("setGameProps", {
            room: roomId,
            values: {
              backgroundColor: "white"
            }
          })
          setGameProps({
            values: {
              backgroundColor: "white"
            }
          })
        }}></span>}
        
      </div>
      <br/>
    </div>
  )
}
