
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from 'react-tippy';
import { Popover } from 'reactstrap';
import shortid from "shortid";
import "../../App.css";
import { AppContext } from "../../AppContext";
import { useStore } from "../../store";
import Icons from "../Icons";
import { LeftEarly } from '../LeftEarly';
import { MainCanvas } from "../MainCanvas";
import { Spectator } from '../Spectator';
import { PrimaryButton, SecondaryButton } from "../utils/Buttons";
import { EndScreen } from "./EndScreen";
const useStyles = makeStyles((theme) => ({
  border: {
    boder: "1px solid #e8e8e8",
    paddingLeft: "10%",
    paddingRight: "10%",
    baccgroundColor: "black"
  },
  button: {
    textTransform: "none",
    fontSize: 17,
    marginLeft: 20,
    marginRight: "20px",
    borderRadius: "20px"
  },
}))

export default function Leftpanel() {
  const [id] = useState(shortid.generate())
  const [doneDrawing, setDoneDrawing] = useState(false);
  const { modals, setModal, users, socket, gameProps, setGameProps, roomId, addToGameProp } = useStore(
    state => ({
      gameProps: state.gameProps,
      users: state.users,
      setGameProps: state.setGameProps,
      refresh: !state.refresh,
      socket: state.socket,
      roomId: state.roomId,
      addToGameProp: state.addToGameProp,
      addToGameProp: state.addToGameProp,
      modals: state.modals,
      setModal: state.setModal
    }))
  const classes = useStyles();
  const { height, width, isMobile } = useContext(AppContext);
  const [showPopups, setShowPopups] = useState(false);

  useEffect(() => {
    window.setTimeout(() => {
      setShowPopups(true);
    }, 300)
  }, [])

  useEffect(() => {
    setDoneDrawing(false);
  }, [gameProps.index, gameProps.squiggleEnded])

  const userIndex = gameProps.participants.findIndex(s => s === socket.id);

  if (gameProps.squiggleEnded) {
    return <EndScreen />
  }
  if (gameProps.participants.length === 0) {
    return "ERROR , Please contact website administrator";
  }

  if (userIndex === -1) {
    return <Spectator />
  }

  let someoneLeft = false;
  gameProps.participants.map((participant) => {
    if (!Object.keys(users).includes(participant)) {
      someoneLeft = true;
    }
  })
  if (someoneLeft) {
    return <LeftEarly />
  }



  const getNames = () => {
    const doneUsers = gameProps.participants.filter((x, i) => gameProps.doneDrawings[i])
    if (!doneUsers.length) {
      return "Nobody";
    }
    return doneUsers.map((p, index) => {
      if (users[p]) {
        if (index !== doneUsers.length - 1)
          return `${JSON.parse(users[p]).name}, `
        return JSON.parse(users[p]).name
      }
      return "";
    })
  }
  return (
    <div>
      <br></br>
      <br></br><br></br><br />
      <Grid container>
        <Grid item xs={1} sm={1} lg={1}>
          <Icons />
        </Grid>

        <Grid item xs={11} sm={11} lg={11}>
          <center>
            <div className={classes.border}>
              <MainCanvas
                curGame={"squiggle"}
                userId={id}
                editable={true}
                index={gameProps.index}
                width={Math.min(700, height * 0.6, width * 0.6) + "px"}
                height={Math.min(700, height * 0.6, width * 0.6) + "px"}
                drawingData={gameProps.squiggleDrawings[(gameProps.index + userIndex) % gameProps.participants.length].flat()}
                drawingIndex={(gameProps.index + userIndex) % gameProps.participants.length}
              />
            </div>
            <div style={{ position: "relative", marginLeft: "5px", marginTop: "0px", zIndex: 100, background: "#e8e8e8", width: 6 + Math.min(700, height * 0.6, width * 0.6) + "px", marginBottom: "20px" }}>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginLeft: "20px" }}>

                  <Tooltip
                    disabled={window.mobileAndTabletCheck()}
                    // options
                    title="Ready to switch"
                    position="bottom"
                    style={{ zIndex: 10000 }}
                  >
                    <PrimaryButton style={{ fontSize: isMobile ? "14px" : "" }} id="switchButton" size="small" onClick={() => {
                      setDoneDrawing(true);
                      let newDoneDrawings = [...gameProps.doneDrawings];
                      newDoneDrawings = newDoneDrawings.map((x, i) => i === userIndex ? true : x)
                      if (newDoneDrawings.findIndex(x => x === false) === -1) {
                        setGameProps({ values: { index: gameProps.index + 1, doneDrawings: gameProps.doneDrawings.map(x => false) } })
                        socket.emit("setGameProps", {
                          room: roomId,
                          values: {
                            doneDrawings: gameProps.doneDrawings.map(x => false),
                            index: gameProps.index + 1
                          }
                        })
                        return;
                      }

                      setGameProps({ values: { doneDrawings: newDoneDrawings } })
                      socket.emit("setGameProps", {
                        room: roomId,
                        values: {
                          doneDrawings: newDoneDrawings
                        }
                      })
                    }} disabled={doneDrawing} >
                      Switch
                      </PrimaryButton>
                  </Tooltip>
                </div>
                <div style={{ marginRight: "20px" }}>


                  <Tooltip
                    disabled={window.mobileAndTabletCheck()}
                    // options
                    title="End game and view drawing"
                    position="bottom"
                    style={{ zIndex: 10000 }}
                  >
                    <SecondaryButton size="large" onClick={() => {
                      setGameProps({ values: { squiggleEnded: true } })
                      socket.emit("setGameProps", {
                        room: roomId,
                        values: {
                          squiggleEnded: true
                        }
                      })
                    }}>
                      <CheckOutlinedIcon fontSize={isMobile ? "small" : "default"} />
                    </SecondaryButton>
                  </Tooltip>
                </div>
              </div>
                Who's ready to switch: {isMobile && <br />} {getNames()}
              <br /><br />
            </div>
          </center>
        </Grid>
      </Grid>

      {showPopups && <Popover style={{ marginTop: "-10px" }} placement="top" isOpen={gameProps.curGame === "squiggle" && modals.squiggle === 2} target="switchButton" toggle={() => {
        setModal({
          squiggle: 3
        })
      }}>
        <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
          <center>
            <Typography variant="subtitle1" >
              Click Switch when <br />you’re ready to swap canvases
            </Typography>
          </center>
        </div>
        <center>
          <div class="triangleup">
          </div>
        </center>
      </Popover>}
    </div>
  )
}

