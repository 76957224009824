
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import React, { useContext, useState, useEffect } from "react";
import { Tooltip } from 'react-tippy';
import { Popover } from 'reactstrap';
import shortid from "shortid";
import "../App.css";
import { AppContext } from "../AppContext";
import { useStore } from "../store";
import Icons from "./Icons";
import { MainCanvas } from "./MainCanvas";
import Subhead from "./Subhead";
import { PrimaryButton, SecondaryButton } from "./utils/Buttons";


const useStyles = makeStyles((theme) => ({
  border: {
    boder: "1px solid #e8e8e8",
    paddingLeft: "10%",
    paddingRight: "10%",
    baccgroundColor: "black"
  },
  button: {
    textTransform: "none",
    fontSize: 17,
    marginLeft: 20,
    marginRight: "20px",
    borderRadius: "20px"
  },
}))

export default function Leftpanel() {
  const [id] = useState(shortid.generate())
  const { setModal, modals, socket, gameProps, setGameProps, roomId, addToGameProp } = useStore(state => ({
    gameProps: state.gameProps, setGameProps: state.setGameProps, refresh: !state.refresh, socket: state.socket, roomId: state.roomId, addToGameProp: state.addToGameProp,
    setModal: state.setModal,
    modals: state.modals
  }))
  const classes = useStyles();
  const { height, width, isMobile } = useContext(AppContext);
  const [showPopups, setShowPopups] = useState(false);

  useEffect(() => {
    window.setTimeout(() => {
      setShowPopups(true);
    }, 300)
  }, [])

  return (
    <div>
      <Subhead></Subhead>
      <br></br>
      <br></br>
      <Grid container>
        <Grid item xs={1} sm={1} lg={1}>
          <Icons />
        </Grid>

        <Grid item xs={11} sm={11} lg={11} style={{ "marginTop": "-20px" }}>
          <center>
            <div className={classes.border}>
              <MainCanvas
                userId={id}
                editable={true}
                width={Math.min(700, height * 0.55, width * 0.55) + "px"}
                height={Math.min(700, height * 0.55, width * 0.55) + "px"}
                drawingData={gameProps.drawing}
              />
            </div>
            <div style={{ position: "relative", marginLeft: "5px", marginTop: "0px", zIndex: 100, background: "#e8e8e8", width: 6 + Math.min(700, height * 0.55, width * 0.55) + "px", marginBottom: "20px" }}>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginLeft: "20px" }}>
                  <Tooltip
  disabled={window.mobileAndTabletCheck()}
                    // options
                    title="Set drawing to background"
                    position="bottom"
                    style={{ zIndex: 10000 }}
                  >
                    <PrimaryButton style={{fontSize: isMobile ? "14px" : ""}}  id="doneButton" size="small" onClick={() => {
                      setGameProps({ values: { backgroundDrawing: [[gameProps.drawing]] } })
                      socket.emit("setGameProps", {
                        room: roomId,
                        values: {
                          backgroundDrawing: [[gameProps.drawing]]
                        }
                      })
                    }} >
                      <div style={{marginLeft: isMobile ? "0px" : "10px", marginRight: isMobile ? "0px" : "10px"}}>
                      Done
                      </div>
                      </PrimaryButton>
                  </Tooltip>
                </div>

                <div style={{ marginRight: "20px" }}>
                  <Tooltip
  disabled={window.mobileAndTabletCheck()}
                    // options
                    title="Clear tiles"
                    position="bottom"
                    style={{ zIndex: 10000 }}
                  >
                    <SecondaryButton style={{fontSize: isMobile ? "10px" : ""}} onClick={() => {
                      setGameProps({ values: { backgroundDrawing: [] } })
                      socket.emit("setGameProps", {
                        room: roomId,
                        values: {
                          backgroundDrawing: []
                        }
                      })
                    }} >
                      <BackspaceOutlinedIcon fontSize={isMobile ? "small" : "default"} />
                    </SecondaryButton>
                  </Tooltip>
                </div>
              </div>
              <br />
            </div>

            {showPopups && <Popover style={{ marginTop: "-40px" }} placement="top" isOpen={gameProps.curGame === "drawing" && modals.drawing === 2} target="doneButton" toggle={() => {
              if(modals.drawing === 2)
                setModal({
                  drawing: 4
                })
            }}>
              <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
                <center>
                  <Typography variant="subtitle1" >
                    Click Done to set<br /> your drawing to background
                    </Typography>
                </center>
              </div>
              <center>
                <div class="triangleup">
                </div>
              </center>
            </Popover>}
          </center>
        </Grid>
      </Grid>
    </div>
  )
}

