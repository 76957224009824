import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
export default function (props) {
  return (
    <div>
      <center>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
         <CircularProgress disableShrink />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </center>
    </div>
  );
}
