import { Grid } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import swal from 'sweetalert';
import { AppContext } from '../AppContext';
import useSocket from '../hooks/useSocket';
import { useStore } from '../store';
import Background from './Background';
import CorpseLeftPanel from "./corpse/LeftPanel";
import Header from './Header';
import Leftpanel from './Leftpanel';
import Loader from './Loader';
import Rightpanel from './Rightpanel';
import SquiggleLeftPanel from "./squiggle/LeftPanel";
import avatar from "./utils/avatar";

export const GamePage = ({ isAdmin }) => {
  const socket = useSocket({ isAdmin });
  const { setModal, roomId, modals, setMediasoupObj, setMediasoupConsumers, backgroundDrawing, backgroundColor, gameProps, gameStarted } = useStore(store => ({
    gameProps: store.gameProps,
    roomId: store.roomId,
    setMediasoupObj: store.setMediasoupObj,
    setMediasoupConsumers: store.setMediasoupConsumers,
    backgroundDrawing: store.gameProps.backgroundDrawing,
    backgroundColor: store.gameProps.backgroundColor,
    gameStarted: store.gameStarted,
    modals: store.modals,
    setModal: store.setModal
  }))

  const { isMobile, width } = useContext(AppContext)

  const getGame = () => {
    if (gameProps.isLoading)
      return <Loader />
    if (gameProps.curGame === "drawing")
      return <Leftpanel />
    if (gameProps.curGame === "corpse") {
      return <CorpseLeftPanel />
    }
    if (gameProps.curGame === "squiggle") {
      return <SquiggleLeftPanel />
    }
  }

  const startAudioMs = (socket) => {
    let temp = new avatar("wss://audio.sometimes.live", roomId, socket.id);
    temp.setAudioHandler(function (track, peerId, consumer) {
      console.log("Connecting to peer", peerId);
      let audioelement = document.createElement("audio");
      audioelement.setAttribute("autoplay", true);
      audioelement.setAttribute("playsinline", true);
      audioelement.setAttribute("id", "audio" + peerId);
      const stream = new MediaStream();
      stream.addTrack(track);
      audioelement.srcObject = stream;
      document.body.appendChild(audioelement)
      let temp = {};
      temp[peerId] = consumer;
      setMediasoupConsumers({
        ...temp
      })
    })

    temp.setPeerDisconnectHandler(() => {
      console.log("disconnect");
    })

    window.setTimeout(() => {
      temp.init(() => { })
      setMediasoupObj(temp);
    }, 4000)
  }

  useEffect(() => {
    if (gameStarted) {
      window.setTimeout(() => {
        startAudioMs(socket);
      }, 100)
      if ((navigator.userAgent.match('CriOS') || navigator.userAgent.match('FxiOS'))) {
        swal("Please switch to a Safari browser if you want to use audio")
      }
    }

  }, [gameStarted])

  if (!socket) {
    return "";
  }

  const setModals = () => {
    if (gameProps.curGame === "drawing" && modals.drawing === 5) {
      setModal({
        drawing: 6
      })
    }
  }

  return (
    <div onMouseDown={() => { setModals() }}>
      <Header />
      <div style={{ height: "7px" }}>
      </div>
      <div
        style={{ marginTop: "-6px", minHeight: "100vh", overflow: "hidden", zIndex: -100, background: gameProps.backgroundColor || "white" }}
        class="sideFontSmall"
      >
        <div style={{ "height": "125vh" }}>
          <Grid container style={{ "marginTop": "2px", zIndex: -10 }}>

            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Background drawing={backgroundDrawing} />
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ marginTop: "-125vh" }}>
        <Grid container>

          <Grid item xs={12} sm={12} md={9} lg={9}>
            <div style={{ marginTop: "-2px" }}></div>
            {getGame()}
          </Grid>

          <Grid style={{ position: "relative", zIndex: 100, }} item xs={12} sm={12} md={3} lg={3}>
            <div style={{ marginTop: "-19px" }}></div>
            {isMobile && !((!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) || window.safari !== undefined) && width < 500 && <> <br /><br /></>}
            {isMobile && ((!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) || window.safari !== undefined) && width < 500 && <> <br /><br /></>}

            <Rightpanel />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
