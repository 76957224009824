import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import { Tooltip } from 'react-tippy';
import { useStore } from '../store';
import { SecondaryButton } from './utils/Buttons';
import { CentralizedTextField } from "./utils/CentralizedTextField";
const copy = require('clipboard-copy')
const useStyles = makeStyles((theme) => ({
    button1: {
        background: "white",
        color: "black",
        marginLeft: 5,
        marginRight: 5,
        marginTop: 2,
        borderRadius: 20,
        marginBottom: 5,
        fontSize: 18,
        align: "center",
        textTransform: "none"

    },
    root: {
        backgroundColor: "#e8e8e8",
        height: 80,
        border: "1px solid white"
    },
    subRoot: {
        paddingTop: 10,
        margin: "auto"
    },
    button: {
        margin: theme.spacing(1),
        marginLeft: 20,
        marginRight: "20px",
        backgroundColor: "#ffffff",
        color: "black",
        borderRadius: "20px",
        fontWeight: "bolder"
    },

    grid: {
        backgroundColor: "#e8e8e8",
    },

}));


export default function Rpgrid1() {
    const classes = useStyles();
    const { roomId } = useStore(state => ({ roomId: state.roomId }));

    return (

        <div className={classes.root}>
            <div className={classes.subRoot}>
                <div style={{ "display": "flex", justifyContent: "center" }}>
                    <Typography variant="subtitle1" style={{ paddingTop: "10px" }}>
                        <b>
                            Room code
                        </b>
                    </Typography>
                    <Tooltip
  disabled={window.mobileAndTabletCheck()}
                        // options
                        title="Copy room URL"
                        position="bottom"
                    >
                        <CentralizedTextField style={{ "width": "45px", marginTop: "4px", border: "none", outline: "none", marginLeft: "10px" }} value={roomId} />
                    </Tooltip>

                    <div style={{ marginTop: "3px" }}>
                        <Tooltip
  disabled={window.mobileAndTabletCheck()}
                            // options
                            title="Copy URL"
                            position="bottom"
                        >
                            <SecondaryButton size="large" onClick={() => copy(`https://magnificently.live/join/${roomId}`)} >
                                <img width="20px" src="/copy.png" />
                            </SecondaryButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>


    )
}

