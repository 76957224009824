import { Typography } from '@material-ui/core'
import React, {useContext} from 'react'
import { AppContext } from '../AppContext'

export const Spectator = () => {
  const {isMobile} = useContext(AppContext)

  return (
    <div> <br /><br /><br /> {!isMobile && <> <br /><br /><br /> </>}
      <center>
        <div style={{ borderRadius: "10px", boxShadow: "2px black",  width: isMobile ? "65%" : "55%", background: "white", position: "relative", zIndex: 1000, background: "black", color: "white" }}>
          <br /><br /><br />
          {!isMobile && <> <br /><br /><br /> </>}
          <Typography variant="h3">
            Welcome!
        </Typography><br />
          <Typography variant="h5" style={{padding: "20px"}}>
            Please wait a minute for the next round to start.
        </Typography>
          <br /><br />{!isMobile && <> <br /><br /><br /> </>}
        </div>
      </center>
    </div >
  )
}
