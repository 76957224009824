import { Typography } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import React, { useContext, useEffect, useRef, useState } from "react";
import { SketchField, Tools } from "react-sketch";
import shortid from "shortid";
import "../../App.css";
import { AppContext } from "../../AppContext";
import { useStore } from "../../store";

const WIDTH = 300;

export const CorpseCanvas = function (props) {
  const { gameProps, modals, setModal, socket, addToGameProp, roomId, refresh, size, color } = useStore(store => ({
    socket: store.socket, addToGameProp: store.addToGameProp, roomId: store.roomId, refresh: store.refresh, size: store.size, color: store.color,
    modals: store.modals, setModal: store.setModal, gameProps: store.gameProps

  }));
  const [showPopover, setShowPopover] = useState(false);
  const { isMobile } = useContext(AppContext);

  const [clearTitle, setClearTitle] = useState(true);

  const getHeight = () => {
    return (props.height.substr(0, props.height.length - 2))
  }

  useEffect(() => {
    window.setTimeout(() => {
      setShowPopover(true);
    }, 100)
  }, [])

  useEffect(() => {
    sketch.clear();
    if (props.index != 0) addPrevDrawing();
    setClearTitle(true)
    addCurDrawing();
  }, [props.index, props.drawings]);


  useEffect(() => {
    if (modals.corpse === 3 || modals.corpse === 5) {
      setModal({
        corpse: modals.corpse + 1
      })
    }
  }, [props.index])

  const addCurDrawing = function () {
    let drawingData = props.drawings[props.index];
    if (!drawingData) {
      return;
    }
    for (let j = 0; j < drawingData.length; j++) {
      addObjectToCanvas(j, 0, 0, drawingData);
    }
  }

  const addPrevDrawing = function () {
    let drawingData = props.drawings[props.index - 1];
    if (!drawingData) {
      return;
    }
    for (let j = 0; j < drawingData.length; j++) {
      addObjectToCanvas(j, 0, -(props.height.substr(0, props.height.length - 2)) * 0.90, drawingData);
    }
  };

  const addObjectToCanvas = function (index, left, top, drawingData) {
    let values = JSON.parse(drawingData[index].data);
    let width = drawingData[index].width;
    let height = drawingData[index].height;
    values["left"] = (values["left"] * outerRef.current.clientWidth) / width + left;
    values["top"] = (values["top"] * outerRef.current.clientHeight) / height + top;
    values["width"] = (values["width"] * outerRef.current.clientWidth) / width;
    values["height"] =
      (values["height"] * outerRef.current.clientHeight) / height;
    values["strokeWidth"] =
      (values["strokeWidth"] * outerRef.current.clientHeight) / height;
    values.path = values.path.map((val) => {
      for (let i = 1; i < val.length; i++) {
        if (i % 2 == 1) {
          val[i] = (val[i] * outerRef.current.clientWidth) / width;
        } else {
          val[i] = (val[i] * outerRef.current.clientHeight) / height;
        }
      }
      return val;
    });
    sketch.addObject(
      JSON.stringify({
        ...{ sender: drawingData[index].sender },
        ...values,
      })
    );
  };

  const undo = () => {
    if (!props.drawings) {
      return;
    }
    sketch.clear();
    let drawingData = props.drawings[props.index];
    if (!drawingData) {
      return;
    }
    for (let j = 0; j < drawingData.length - 1; j++) {
      addObjectToCanvas(j, 0, 0, drawingData);
    }
    // props.socket.emit("undoCorpseDrawing", {
    //   room: props.room,
    //   index: props.index,
    //   userIndex: props.userIndex,
    // })

    if (props.index == 0) {
      return;
    }
    if (props.index != 0) addPrevDrawing();
  }

  const getTextMargin = () => {
    return "145px"
    if (!props.width) {
      return 100;
    }

    if (props.width.endsWith("px")) {
      return props.width.substr(0, props.width.length - 2) / 2 * 1.2 + "px";
    }

    if (props.width.endsWith("%")) {
      return props.width.substr(0, props.width.length - 1) / 2 * 1.2 + "%";
    }
    return props.width / 2 * 1.2;
  }

  const sketchUpdated = function (obj, action, sender, id = null) {
    if (!outerRef || !outerRef.current) {
      return;
    }
    setClearTitle(false);
    let length_per_part = 800000;
    let loop_count = Math.ceil(obj.length / length_per_part);

    let from_str_index = 0;
    for (let x = 0; x < loop_count; x++) {
      const str_part = obj.substr(from_str_index, length_per_part);

      const payload = {
        action: action,
        id: id,
        data: str_part,
        sender: props.userId,
        width: outerRef.current.clientWidth,
        height: outerRef.current.clientHeight,
      };

      if (x + 1 === loop_count) {
        Object.assign(payload, { is_final: true });
      }

      from_str_index += length_per_part;

      socket.emit("addToGameProp", {
        keys: ["corpseDrawings", props.drawingIndex, props.index],
        value: payload,
        room: roomId
      })
      addToGameProp({ keys: ["corpseDrawings", props.drawingIndex, props.index], value: payload })
      if (modals.corpse === 1) {
        setModal({
          corpse: 2
        })
      }
    }
    //addPrevDrawing();
  };

  let sketch = useRef(null);
  let outerRef = useRef(null);
  const getText = () => {
    if (props.index === 0) {
      return "Draw from the head"
    }
    if (props.index === 1) {
      return "Draw from the neck"
    }
    if (props.index === 2) {
      return "Draw from the waist"
    }
  }
  const getSubText = () => {
    if (props.index === 0) {
      return "to the bottom of the neck"
    }
    if (props.index === 1) {
      return "to the waist"
    }
    return "to the bottom of the feet"
  }

  const getSize = (val) => {
    if (val === "h5") {
      return !isMobile ? "h5" : "subtitle1";
    }
    if (val === "h6") {
      return !isMobile ? "h6" : "subtitle2";
    }
    return !isMobile ? "h6" : "subtitle2";
  }

  return (
    <div>
      <div id="corpseCanvas" style={{
        background: "white", marginTop: "20px", zIndex: 100, position: "relative",
        width: props.width || "300px"
      }}>
        {/* {props.editable && clearTitle && <>
        <div style={{ height: getHeight(), "position": "relative", "left": - getHeight() * 0.5  }}>
          <span style={{ "position": "relative",top: getHeight() * 0.4, left: getHeight()/11, fontSize: getHeight()/25 }}>
            <h2><b>{props.index + 1}</b></h2>
          </span>
          <img src={"/cappper.png"} style={{ "height": getHeight() * 0.4, position: "relative", top: getHeight() * 0.125, left: getHeight()/6 }}></img>
          <span style={{ "position": "relative",top: -getHeight() * 0.3, left: getHeight() * 0.55, fontSize: getHeight()/18 }}>
            <h2><b>Draw here!</b></h2>
          </span>
          <span style={{ "position": "relative",top: -getHeight() * 0.35, left: getHeight() * 0.55, fontSize: getHeight()/30 }}>
            {getText()}<br/>
            {getSubText()}
          </span>
        </div>
        <span style={{ "position": "relative",top: getHeight() * 0.095, left: 0, fontSize: getHeight()/30 }}>
            Others will only see what's drawn beneath the yellow line
          </span>
        <div style={{marginTop: -(props.height.substr(0, props.height.length - 2)) * 0.88}}></div>
      </>} */}
        {<div style={{ position: "absolute", top: "10px", left: 10, zIndex: 1000 }}>
          {props.index + 1} of 3
      </div>}
        <div
          ref={outerRef}
          style={{

            width: props.width || "300px",
            "padding-top": props.width || "300px",
            position: "relative",
            "pointer-events": props.editable ? "auto" : "none",
          }}
        >
          <SketchField
            className={`canvas ${!props.borderDisabled ? "blackBorder" : ""}`}
            style={{
              background: "white",
              position: "absolute",
              top: "0",
              left: "0",
              bottom: "0",
              right: "0",
            }}
            id="canvas"
            width={props.width || "300px"}
            height={props.width || "300px"}
            tool={Tools.Pencil}
            lineColor={color || "black"}
            lineWidth={size || 3}
            ref={(c) => (sketch = c)}
            shortid={shortid}
            username={props.userId}
            onUpdate={sketchUpdated}
          />
        </div>
        <div
          style={{
            width: props.width,
            height: "3px",
            background: props.index !== 2 ? "#f1c40f" : "",
            marginTop: -(props.height.substr(0, props.height.length - 2)) * 0.10,
            zIndex: "1",
            position: "relative",
            zIndex: 100
          }}
        ></div>
        <div style={{ height: (props.height.substr(0, props.height.length - 2)) * 0.10, overflow: "hidden" }}> </div>

        {showPopover && <> <Popover
          id={"popover123212"}
          open={gameProps.curGame === "corpse" && (modals.corpse === -1)}
          anchorEl={document.getElementById("corpseCanvas")}
          onClose={() => {
            setModal({
              corpse: modals.corpse + 1
            })
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ marginTop: "-100px" }}
        >
          <center>
            <div class="triangle">
            </div>
          </center>
          <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
            <center>
              <Typography variant={getSize()} >
                Each player draws part of a creature's body, <br />without getting to see what the <br />other person has drawn. <br />The full creature’s revealed at the end.
          </Typography>
            </center>
          </div>
        </Popover>

          <Popover
            className="popover_class"

            id={"popover12322"}
            open={gameProps.curGame === "corpse" && (modals.corpse === 0 || modals.corpse === 4 || modals.corpse === 6)}
            anchorEl={document.getElementById("corpseCanvas")}
            onClose={() => {
              setModal({
                corpse: modals.corpse + 1
              })
            }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ marginTop: "-100px" }}
          >
            <center>
              <div class="triangle">
              </div>
            </center>
            <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
              <center>
                <Typography variant={getSize("h4")} >
                  {props.index + 1}.
          </Typography>
                <Typography variant={getSize("h5")}>
                  {getText()}<br />
                  {getSubText()}
                </Typography>
              </center>
            </div>
          </Popover>

          <Popover
            id={"popover12322"}
            open={gameProps.curGame === "corpse" && modals.corpse === 2}
            anchorEl={document.getElementById("corpseCanvas")}
            onClose={() => {
              setModal({
                corpse: 3
              })
            }}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ marginTop: getHeight() / 2.4 }}
          >
            <div>
              <center>
                <div class="triangle">
                </div>
              </center>
              <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
                <center>
                  <Typography variant="subtitle1">
                    Other players will see what's <br /> drawn beneath the yellow line
                  </Typography>
                </center>
              </div>
            </div>
          </Popover> </>}
      </div >
    </div>
  );
}