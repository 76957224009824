import React, { useEffect } from 'react';

export default function Ads() {
  useEffect(() => {
    // (window.adsbygoogle || []).push({});
  }, [])

  return (
    <div className='ad'>
      <ins className='adsbygoogle'
        style={{ display: 'block' }}
        data-ad-client='ca-pub-6519344612000816'
        data-ad-slot='3129794281'
        data-ad-format='auto' />
    </div>
  );
}