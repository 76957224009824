import React, { useEffect, useState } from "react";
import HugeCanvas from "./HugeCanvas";
/*
 * Shows the background 
 * in the form of tiles. 
 * The component creates a single canvas outside the view of the browser
 * and repliaces (copy and pastes) that canvas to the browser. This 
 * happens so we can save on render cycles because rendering on the 
 * whiteboard is a pain.
 */
export default function (props) {
  let drawing = [];
  const [localDrawing, setLocalDrawing] = useState([[]]);
  if (props.drawing) {
    drawing = props.drawing;
  }

  const getHeightFactor = () => {
    let factor = 1;
    props.drawing.map((d) => {
      if (d[0] && d[0].url) {

      } else {
        factor = Math.max(factor, d.length)
      }
    })
    return factor
  }

  useEffect(() => {
    let d1 = props.drawing;
    setLocalDrawing((d) => {
      console.error("hi")
      console.log(d);
      console.log(d1);
      if(d && d[0] && d[0][0] && d1 && d1[0] && d1[0][0] && d1[0][0].length === d[0][0].length) {
        return d;
      }
      console.log(d[0]);
      console.log(d1[0])
      window.setTimeout(() => {
        let oldCanvas = document.getElementsByClassName("lower-canvas")[0];
        if (!oldCanvas) {
          return;
        }
        for (let i = 1; i <= 130; i++) {
          let newCanvas = document.getElementById("canvas" + i);
  
          if (d1 == null || d1.length == 0) {
            continue;
          }
          if (!newCanvas || !newCanvas.getContext) {
            return;
          }
          let context = newCanvas.getContext("2d");
          context.clearRect(
            0,
            0,
            100 * d1.length,
            100 + (getHeightFactor() - 1) * 90
          );
          context.drawImage(
            oldCanvas,
            0,
            0,
            100 * d1.length,
            100 + (getHeightFactor() - 1) * 90
          );
        }
      }, 200);
      return d1;
    });
  }, [props.drawing]);

  let arr = [];
  for (let i = 0; i < 280; i++) {
    arr.push(i + 1);
  }
  return (
    <div
      id="bigCanvasContainer"
      style={{
        width: "115%",
        zIndex: -100,
      }}
    >
      <div
        style={{
          float: "right",
          position: "absolute",
          right: "0px",
          top: "1500px",
        }}
      >
        <HugeCanvas
          borderDisabled={true}
          drawingData={props.drawing}
          height={100 + (getHeightFactor() - 1) * 90}
          jumpHeight={90}
          editable={false}
          width={100}
          netWidth={100 * props.drawing.length}
          style={{
            float: "right",
            position: "absolute",
            right: "0px",
            top: "40px",
          }}
        />
      </div>
      {arr.map((val) => (
        <div
          style={{
            width: `${100 * props.drawing.length}px`,
            height: `${
              100 + ((getHeightFactor() - 1)) * 90
              }px`,
            float: "left",
          }}
        >
          <canvas
            id={"canvas" + val}
            width={`${100 * props.drawing.length}`}
            height={`${
              100 + (getHeightFactor() - 1) * 90
              }`}
            style={{ margin: "0px" }}
          ></canvas>
        </div>
      ))}
    </div>
  );
}
