
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from "react";
import { Tooltip } from 'react-tippy';
import shortid from "shortid";
import "../../App.css";
import { AppContext } from "../../AppContext";
import { useStore } from "../../store";
import { MainCanvas } from "../MainCanvas";
import { PrimaryButton, SecondaryButton } from "../utils/Buttons";
const useStyles = makeStyles((theme) => ({
  display: {
    padding: 20,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#e8e8e8",
  },

  iconbox: {
    padding: 5,
    paddingTop: "10px",
    paddingBottom: "15px",
    marginLeft: 10,
    marginRight: 10,
    display: "flex",
    justifyContent: "space-between"
  },

}))

export const EndScreen = () => {
  const names =  ["Ta-da!", "Behold!", "Bravo!", "Hooray!", "Bingo!", "Presto!", "Voila!"];
  const [id] = useState(shortid.generate())
  const { height, width, isMobile } = useContext(AppContext);
  const [uploaded, setUploaded] = useState({});
  const [selectdPhrase, setSelectedPhrase] = useState(names[Math.floor(Math.random() * names.length)])
  const classes = useStyles();
  const { users, setGameProps, roomId, socket, gameProps } = useStore(state => ({
    users: state.users,
    setGameProps: state.setGameProps,
    users: state.users,
    roomId: state.roomId,
    socket: state.socket,
    gameProps: state.gameProps
  }))
  const download = (id) => {
    const canvas = document.getElementById(id).querySelector("canvas");
    const image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
    var link = document.createElement('a');
    link.download = "drawing.png";
    link.href = image;
    link.click();
  }
  const startSquiggle = () => {
    socket.emit("setGameProps", {
      room: roomId,
      values: {
        participants: Object.keys(users),
        curGame: "squiggle",
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        index: 0,
        squiggleEnded: false,
        squiggleDrawings: new Array(Object.keys(users).length).fill([[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []])
      }
    })
    setGameProps({
      values: {
        squiggleEnded: false,
        participants: Object.keys(users),
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        curGame: "squiggle",
        index: 0,
        squiggleDrawings: new Array(Object.keys(users).length).fill(false).map(x => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []])
      }
    })
  }

  const upload = (id, index) => {
    const canvas = document.getElementById(id).querySelector("canvas");
    const image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
    fetch("https://api.cloudinary.com/v1_1/dqzrpmfdk/image/upload", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "upload_preset": "ivx51bzo",
        "file": image
      })
    })
      .then(response => response.json())
      .then(response => {
        socket.emit("email", {
          link: response.secure_url
        })
      })
    let uploadedTemp = { ...uploaded };
    uploadedTemp[index] = true;
    setUploaded(uploadedTemp)
  }


  return (
    <div style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "4%", position: "relative", zIndex: 100 }}>
      {/* <Subhead /> */}
      <div style={{ background: "#e8e8e8" }}>
        <br />
        <center>
          <Typography variant="subtitle1">
            <b>
              {selectdPhrase}
            </b>
          </Typography>
          <span style={{float: "right", marginRight: "10px", marginTop: isMobile ? "-32px" : "-35px"}}>
            <PrimaryButton style={{fontSize: isMobile ? "14px" : ""}} size={isMobile ? "small" : "large"} onClick={startSquiggle}>Play again</PrimaryButton>
          </span>
        </center>
        <Grid container justify="center" spacing={2} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
          <br /><br />
          {gameProps.participants.map((x, index) => {
            return <Grid item xs={12} sm={6} md={6} lg={6}>
              <div>
                <MainCanvas
                  borderDisabled={true}
                  curGame={"squiggle"}
                  userId={id}
                  editable={false}
                  width={"100%"}
                  height={"100%"}
                  drawingData={gameProps.squiggleDrawings[index].flat()}
                  id={`canvasDrawing-${index}`}
                />
                <div className={classes.iconbox}>

                  <Tooltip
  disabled={window.mobileAndTabletCheck()}
                    // options
                    title="Download"
                    position="bottom"
                  >
                    <SecondaryButton size={isMobile ? "small" : "large"} elevation={0} style={{ width: 29, height: 29, cursor: "pointer" }} onClick={() => download(`canvasDrawing-${index}`)}>
                      <img src="/upload.png" height={20} />
                    </SecondaryButton>
                  </Tooltip>

                  <Tooltip
  disabled={window.mobileAndTabletCheck()}
                    // options
                    title="Submit for display on our site"
                    position="bottom"
                  >
                    <SecondaryButton size={isMobile ? "small" : "large"} disabled={uploaded[index]} elevation={0} style={{ width: 29, height: 29, cursor: "pointer" }} onClick={() => upload(`canvasDrawing-${index}`, index)}>
                      {uploaded[index] ? "Done!" : <img src="/download.png" height={20} />}
                    </SecondaryButton>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          })}
        </Grid>
      </div>
    </div>
  )
}
