
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from 'react-tippy';
import shortid from "shortid";
import "../../App.css";
import { AppContext } from "../../AppContext";
import { useStore } from "../../store";
import Icons from "../Icons";
import { LeftEarly } from "../LeftEarly";
import { Spectator } from '../Spectator';
import { PrimaryButton } from "../utils/Buttons";
import { CorpseCanvas } from "./CorpseCanvas";
import { EndScreen } from "./EndScreen";
const useStyles = makeStyles((theme) => ({
  border: {
    boder: "1px solid #e8e8e8",
  }
}))

export default function Leftpanel() {
  const [id] = useState(shortid.generate())
  const [doneDrawing, setDoneDrawing] = useState(false);
  const { isMobile } = useContext(AppContext);

  const {setModal,  users, socket, gameProps, setGameProps, roomId, addToGameProp } = useStore(
    state => ({
      setModal: state.setModal,
      gameProps: state.gameProps,
      setGameProps: state.setGameProps,
      refresh: !state.refresh,
      socket: state.socket,
      roomId: state.roomId,
      addToGameProp: state.addToGameProp,
      users: state.users,
      addToGameProp: state.addToGameProp
    }))
  const classes = useStyles();
  const { height, width } = useContext(AppContext);
  useEffect(() => {
    setDoneDrawing(false);
    if(gameProps.index === 0) {
      setModal({
        corpse: -1
      })
    }
  }, [gameProps.index])

  const userIndex = gameProps.participants.findIndex(s => s === socket.id);

  if (gameProps.index === 3) {
    return <EndScreen />
  }

  if (gameProps.participants.length === 0) {
    return "ERROR , Please contact website administrator";
  }

  if (userIndex === -1) {
    return <Spectator />
  }

  let someoneLeft = false;
  console.error("hi");
  gameProps.participants.map((participant) => {
    if (!Object.keys(users).includes(participant)) {
      someoneLeft = true;
    }
  })
  if (someoneLeft) {
    return <LeftEarly />
  }

  const getNames = () => {
    const doneUsers = gameProps.participants.filter((x, i) => gameProps.doneDrawings[i])
    if (!doneUsers.length) {
      return "Nobody";
    }
    return doneUsers.map((p, index) => {
      if (users[p]) {
        if (index !== doneUsers.length - 1)
          return `${JSON.parse(users[p]).name}, `
        return JSON.parse(users[p]).name
      }
      return "";
    })
  }

  return (
    <div>
      <br></br>
      <br></br>
      <Grid container>
        <Grid item xs={1} sm={1} lg={1}>
          <Icons />
        </Grid>
        <Grid item xs={11} sm={11} lg={11} style={{ "marginTop": "-20px" }}>
          <div style={{ "display": "flex", justifyContent: "center", }}>
            {!isMobile && <div style={{ marginTop: "100px", marginRight: "20px" }}>
              <center>
                <div style={{ padding: "10px", background: "#e8e8e8", marginLeft: "30px", position: "relative", zIndex: 100, paddingTop: "20px" }}>
                  Example<br /><br />
                  <img src={"/corpse.png"} width="130px" />
                </div>
              </center>
            </div>}
            <div style={{paddingLeft: isMobile ? "80px" : "0px"}}>
              <div>
                <CorpseCanvas
                  userId={id}
                  editable={true}
                  width={Math.min(600, height * 0.6, width * 0.6) + "px"}
                  height={Math.min(600, height * 0.6, width * 0.6) + "px"}
                  drawings={gameProps.corpseDrawings[(gameProps.index + userIndex) % gameProps.participants.length]}
                  drawingIndex={(gameProps.index + userIndex) % gameProps.participants.length}
                  index={gameProps.index}
                />
              </div>
              <div style={{ position: "relative", zIndex: 100, background: "#e8e8e8", width: 6 + Math.min(600, height * 0.6, width * 0.6) + "px", marginBottom: "20px" }}>
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>

                  <Tooltip
  disabled={window.mobileAndTabletCheck()}
                    // options
                    title="Done with this part"
                    position="bottom"
                    style={{ zIndex: 10000 }}
                  >
                    <PrimaryButton style={{fontSize: isMobile ? "14px" : ""}} onClick={() => {
                      setDoneDrawing(true);
                      let newDoneDrawings = [...gameProps.doneDrawings];
                      newDoneDrawings = newDoneDrawings.map((x, i) => i === userIndex ? true : x)
                      if (newDoneDrawings.findIndex(x => x === false) === -1) {
                        setGameProps({ values: { index: gameProps.index + 1, doneDrawings: gameProps.doneDrawings.map(x => false) } })
                        socket.emit("setGameProps", {
                          room: roomId,
                          values: {
                            doneDrawings: gameProps.doneDrawings.map(x => false),
                            index: gameProps.index + 1
                          }
                        })
                        return;
                      }

                      setGameProps({ values: { doneDrawings: newDoneDrawings } })
                      socket.emit("setGameProps", {
                        room: roomId,
                        values: {
                          doneDrawings: newDoneDrawings
                        }
                      })
                    }} size={isMobile ? "small" : "large"} disabled={doneDrawing} variant="contained" color="primary">
                      Done
                      </PrimaryButton>
                  </Tooltip>
                </div>
                <center>
                  { !isMobile && <div style={{fontSize: 5}}> <br /></div>}
                      Who’s done with their part: {isMobile && <div style={{fontSize: "2px"}}> <br /> </div>} {getNames()}
                  <br /><br />
                </center>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div >
  )
}

