import create from 'zustand';

export const useStore = create(set => ({
  socket: null,
  name: null,
  messages: [],
  roomId: null,
  users: {},
  mediasoupObj: null,
  defaultAudio: true,
  mediasoupConsumers: {},
  color: "black",
  size: 10,
  gameStarted: false,
  modals: {
    squiggle: 0,
    drawing: 0,
    corpse: -1
  },
  setModal: (val) => {
    set(state => ({
      modals: {
        ...state.modals,
        ...val
      }
    }))
  },
  setGameStarted: (gameStarted) => {
    set(state => ({ gameStarted }))
  },
  setDefaultAudio: (defaultAudio) => {
    set(state => ({ defaultAudio }))
  },
  setColor: (color) => {
    set(state => ({ color }))
  },
  setSize: (size) => {
    set(state => ({ size }))
  },
  setUsers: (users) => {
    set(state => ({ users }))
  },
  setRoomId: (roomId) => set(state => ({ roomId })),
  setName: (name) => set(state => ({ name })),
  gameProps: {
    participants: [],
    drawing: [],
    backgroundDrawing: [],
    backgroundColor: "white",
    curGame: "drawing",
    index: 0,
    participants: [],
    squiggleDrawings: [],
    squiggleEnded: false,
    corpseDrawings: [],
    doneDrawings: [],
    isLoading: true,
    audioDisabled: false
  },
  addMessage: (vals) => set(state => ({
    messages: [...state.messages, vals]
  })),
  setMediasoupObj: (mediasoupObj) => set(state => ({
    mediasoupObj
  })),
  setMediasoupConsumers: (vals) => set(state => ({
    mediasoupConsumers: { ...state.mediasoupConsumers, ...vals }
  })),
  setSocket: (socket) => set(state => ({ socket })),
  setGameProps: (gameProps) => set(state => {
    return ({
      gameProps: {
        ...state.gameProps,
        ...gameProps.values,
        isLoading: false
      }
    })
  }),
  setLoading: (val) => set(state => {
    return ({
      gameProps: {

        ...state.gameProps,
        isLoading: val
      }
    })
  }),
  addToGameProp: (props) => set(state => {
    console.log("addToGameProp");
    console.log(props);

    const { keys, value, room } = props;

    let vals = JSON.parse(JSON.stringify(state.gameProps));
    let vals2 = JSON.parse(JSON.stringify(state.gameProps));

    console.log(vals2);
    keys.map(key => {
      if (!vals[key]) {
        vals[key] = [];
      }
      vals = vals[key]
    })

    if (value === "undo") {
      const temp = vals;
      if (temp.length > 0) {
        temp.pop();
      }
      vals = temp;
    } else if (value === "clear") {
      vals = [];
    } else {
      vals.push(value);
    }
    console.error("here0");
    console.log(vals2);

    if (keys.length === 1) {
      vals2[keys[0]] = vals;
    }

    if (keys.length === 2) {
      vals2[keys[0]][keys[1]] = vals;
      console.error("here1");
      console.log(vals2);
    }

    if (keys.length === 3) {
      vals2[keys[0]][keys[1]][keys[2]] = vals;
    }
    console.log(vals2);
    return {
      gameProps: vals2,
      refresh: !state.refresh
    }
  })
}))