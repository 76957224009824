import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from '../AppContext';
import { useStore } from '../store';
import { PrimaryButton, TertiaryButton } from './utils/Buttons';
import { CentralizedTextField } from './utils/CentralizedTextField';

const copy = require('clipboard-copy')

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            padding: props => props.isMobile ? theme.spacing(2) : theme.spacing(4),
            borderRadius: 20,
            margin: 10,
            align: "center"
        },
    },

    title: {
        paddingTop: 20,
        align: "center"
    },

    box: {
        marginLeft: 5,
        marginRight: 5,
        marginTop: 30,
        marginBottom: 5,
        align: "center"
    },

    button1: {

        marginLeft: 5,
        marginRight: 5,
        marginTop: 2,
        borderRadius: 20,
        marginBottom: 5,
        backgroundColor: "#e8e8e8",
        fontSize: 18,
        align: "center",
        textTransform: "none"

    },

    button2: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 10,
        backgroundColor: "#5fc8c9",
        color: "white",
        fontSize: 18,
        borderRadius: 20,
        paddingLeft: 40,
        paddingRight: 40,
        align: "center",
        textTransform: "none"
    },

}));


export const Roombox = () => {
    const history = useHistory()
    const { isMobile } = useContext(AppContext);
    const classes = useStyles({ isMobile });
    const { roomId, defaultAudio, setDefaultAudio } = useStore(state => ({ roomId: state.roomId, defaultAudio: state.defaultAudio, setDefaultAudio: state.setDefaultAudio }));

    if (!roomId) {
        history.push("/");
    }
    const getSize = () => {
        if (isMobile) {
            return "h6"
        }
        return "h5"
    }

    return (
        <div style={{ "display": "flex" }}>
            <div className={classes.root}>
                <Paper elevation={0} style={{ marginLeft: "50px", marginRight: "50px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant={getSize()} className={classes.title}>
                            Send friends your room code:
                        </Typography>
                    </div>
                    <br />

                    <div>
                        <Grid container justify="center">

                            <CentralizedTextField value={roomId} style={{ width: isMobile ? "70px" : "150px" }} />
                            <TertiaryButton onClick={() => copy(`https://magnificently.live/join/${roomId}`)}>
                                Copy URL
                              </TertiaryButton>
                        </Grid>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant={getSize()} className={classes.title}>
                            If you want to talk to your friends in the room
                        </Typography>
                    </div>
                    <br />

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <TertiaryButton startIcon={defaultAudio ? <MicIcon /> : <MicOffIcon />} onClick={() => setDefaultAudio(!defaultAudio)}>
                            Audio {defaultAudio ? "on" : "off"}
                        </TertiaryButton>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant={getSize()} className={classes.title}>
                            Invites sent? Meet them in your lobby
                        </Typography>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                        <PrimaryButton onClick={() => history.push("/lobby/true")} >
                          <Typography variant="subtitle1">
                            Enter lobby
                            </Typography>
                      </PrimaryButton>
                    </div>
                    <br />
                </Paper>
            </div>
        </div>
    )
}