import { Typography } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import React, { useEffect, useRef, useState } from "react";
import { SketchField, Tools } from "react-sketch";
import shortid from "shortid";
import { useStore } from "../store";

/*
 * HugeCanvas Component
 * @params - width
 * @params - height
 * @params - size - size of stroke
 * @params - color - color of stroke
 * @params - editable - whether or not the canvas is editable
 * @params - userId - Unique Canvas userid
 */
export const MainCanvas = function (props) {
  const { drawingData } = props;
  const { setModal, gameProps, modals, socket, addToGameProp, roomId, refresh, size, color } = useStore(store => ({
    socket: store.socket,
    addToGameProp: store.addToGameProp,
    roomId: store.roomId,
    refresh: store.refresh,
    size: store.size,
    color: store.color,
    gameProps: store.gameProps,
    modals: store.modals,
    setModal: store.setModal
  }));
  const [clearTitle, setClearTitle] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);

  useEffect(() => {
    sketch.clear();
    if (!drawingData) {
      return;
    }
    for (let j = 0; j < drawingData.length; j++) {
      addObjectToCanvas(j);
    }
    if (drawingData && drawingData.length == 0) {
      //setClearTitle(true);
    } else {
      setClearTitle(false);
    }
  }, [refresh, drawingData]);

  useEffect(() => {
    if (props.curGame === "squiggle") {
      window.setTimeout(() => {
        setShowPopover(true);
        setShowPopover2(false);
      }, 200)
    } else {
      window.setTimeout(() => {
        setShowPopover(false);
        setShowPopover2(true);
      }, 200)
    }
  }, [props.curGame])
  const updateModals = function () {

  }

  const sketchUpdated = function (obj, action, sender, id = null) {
    if (!outerRef || !outerRef.current || !socket) {
      return;
    }
    updateModals();

    let length_per_part = 800000;
    let loop_count = Math.ceil(obj.length / length_per_part);

    let from_str_index = 0;
    for (let x = 0; x < loop_count; x++) {
      const str_part = obj.substr(from_str_index, length_per_part);

      const payload = {
        action: action,
        id: id,
        data: str_part,
        sender: props.userId,
        width: outerRef.current.clientWidth,
        height: outerRef.current.clientHeight,
      };

      if (x + 1 === loop_count) {
        Object.assign(payload, { is_final: true });
      }

      from_str_index += length_per_part;
      if (props.curGame === "squiggle") {
        socket.emit("addToGameProp", {
          keys: ["squiggleDrawings", props.drawingIndex, props.index],
          value: payload,
          room: roomId
        })
        addToGameProp({ keys: ["squiggleDrawings", props.drawingIndex, props.index], value: payload })
        if (modals.squiggle === 1) {
          setModal({
            squiggle: 2
          })
        }
      } else {
        socket.emit("addToGameProp", {
          keys: ["drawing"],
          value: payload,
          room: roomId
        })
        addToGameProp({ keys: ["drawing"], value: payload })
        if (modals.drawing === 1) {
          setModal({
            drawing: 2
          })
        }
      }
    }
  };

  const addObjectToCanvas = function (index) {
    let values = JSON.parse(drawingData[index].data);
    let width = drawingData[index].width;
    let height = drawingData[index].height;
    values["left"] = (values["left"] * outerRef.current.clientWidth) / width;
    values["top"] = (values["top"] * outerRef.current.clientHeight) / height;
    values["width"] = (values["width"] * outerRef.current.clientWidth) / width;
    values["height"] =
      (values["height"] * outerRef.current.clientHeight) / height;
    values["strokeWidth"] =
      (values["strokeWidth"] * outerRef.current.clientHeight) / height;
    values.path = values.path.map((val) => {
      for (let i = 1; i < val.length; i++) {
        if (i % 2 == 1) {
          val[i] = (val[i] * outerRef.current.clientWidth) / width;
        } else {
          val[i] = (val[i] * outerRef.current.clientHeight) / height;
        }
      }
      return val;
    });
    sketch.addObject(
      JSON.stringify({
        ...{ sender: drawingData[index].sender },
        ...values,
      })
    );
  };

  let sketch = useRef(null);
  let outerRef = useRef(null);

  const getTextMargin = () => {
    let dimension = props.height || props.width;
    if (!dimension) {
      return 100;
    }

    if (dimension.endsWith("px")) {
      return dimension.substr(0, dimension.length - 2) / 2 * 1.1 + "px";
    }

    if (dimension.endsWith("%")) {
      return dimension.substr(0, dimension.length - 1) / 2 * 1.1 + "%";
    }
    return dimension / 2 * 1.1;
  }
  const getHeight = () => {
    return (props.height.substr(0, props.height.length - 2))
  }


  return (
    <div id={props.id}>
      {/* {props.editable && clearTitle && <div style={{zIndex: 1000, position: "relative"}}>
      {props.curGame === "squiggle" && <div onMouseDown={() => {setClearTitle(false)}}> 
          <img src={"/squiggleIntro.png"} style={{pointerEvents: "none", marginTop: props.width.substr(0, props.width.length - 2) * 0.3, width: props.width.substr(0, props.width.length - 2) * 0.8}} />
          <h1>
            Draw here!
          </h1>
          Design your background while you wait for your friends to arrive.
          <div style={{marginTop: -1 * props.width.substr(0, props.width.length - 2) * 0.7}} />
          </div>
        }
        {props.curGame !== "squiggle" && <div onMouseDown={() => {setClearTitle(false)}}> 
          <img src={"/drawingIntro.png"} style={{pointerEvents: "none", marginTop: props.width.substr(0, props.width.length - 2) * 0.32, width: props.width.substr(0, props.width.length - 2) * 0.50}} />
          <div style={{marginTop: -1 * props.width.substr(0, props.width.length - 2) * 0.595 }} />
          </div>
        }
      </div>} */}
      <div
        id="bigCanvas"
        ref={outerRef}
        style={{
          width: props.width || "200px",
          "padding-top": props.height || props.width || "200px",
          position: "relative",
          "pointer-events": props.editable ? "auto" : "none",
          background: "white"
        }}
        onMouseDown={() => { setClearTitle(false) }}
      >
        <SketchField
          className={`${!props.borderDisabled ? "blackBorder" : ""} canvas`}
          style={{
            background: "white",
            position: "absolute",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
          }}
          width={props.width || "200px"}
          height={props.height || "200px"}
          tool={Tools.Pencil}
          lineColor={color}
          lineWidth={size}
          ref={(c) => (sketch = c)}
          onUpdate={sketchUpdated}
          shortid={shortid}
          onObjectModified={() => { console.log("objectModified") }}
          username={props.userId}
        />
      </div>
      {showPopover2 && <Popover
        id={"asdads"}
        open={gameProps.curGame === "drawing" && modals.drawing === 0}
        anchorEl={document.getElementById("bigCanvas")}
        onClose={() => {
          setModal({
            drawing: 1
          })
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ marginTop: "-100px" }}
      >
        <center>
          <div class="triangle">
          </div>
        </center>
        <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
          <center>
            <Typography variant="h6" >
              Draw here!
          </Typography>
            <Typography variant="subtitle1" >
              Design your background<br /> while you wait for your friends. <br />
            </Typography>
          </center>
        </div>
      </Popover>}

      {showPopover && <Popover
        id={"asdaads"}
        open={gameProps.curGame === "squiggle" && modals.squiggle === 0}
        anchorEl={document.getElementById("bigCanvas")}
        onClose={() => {
          setModal({
            squiggle: 1
          })
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ marginTop: "-100px" }}
      >
        <center>
          <div class="triangle">
          </div>
        </center>
        <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
          <center>
            <Typography variant="h6" >
              Draw here!
          </Typography>
            <Typography variant="subtitle1" >
              I'll draw on my canvas, you'll draw on yours.<br /> We'll switch canvases and
            <br /> keep drawing and switching until we're done.
          </Typography>
          </center>
        </div>
      </Popover>}
      {/* {props.editable && clearTitle && <> <div style={{ "marginTop": "-" + getTextMargin(), zIndex: 100, position: "relative", "font-size": "18px" }}> {props.curGame == "drawing" ? "Draw a tile here" : "Draw Here"}</div><div style={{ "marginTop": getTextMargin() }}></div><div style={{ "marginTop": "-17px" }}></div></>} */}
    </div >
  );
}
