import React, { useEffect, useRef, useState } from "react";
import { SketchField, Tools } from "react-sketch";
import shortid from "shortid";

const WIDTH = 200;
export default function (props) {
  useEffect(() => {
    sketch.clear();

    if (props.drawingData && props.drawingData.length != 0) {
      for (let i = 0; i < props.drawingData.length; i++) {
        let oneDrawingData = props.drawingData[i];
        if(oneDrawingData.url || oneDrawingData[0].url ) {
          window.fabric.Image.fromURL(oneDrawingData.url || oneDrawingData[oneDrawingData.length - 1].url , function(t) {
            t.set({
              left: i * props.width
            })
            t.scaleToHeight(100, false);
            t.scaleToWidth(100, false);
            
            if(sketch && sketch._fc)
            sketch._fc.add(t);
          })
          continue;
        }
        for (let j = 0; j < oneDrawingData.length; j++) {
          let drawingData = oneDrawingData[j];
          for (let k = 0; k < drawingData.length; k++)
            addObjectToCanvas(
              k,
              i * props.width,
              j * (props.jumpHeight || 180),
              drawingData
            );
        }
      }
    }
  }, [props.drawingData]);

  const addObjectToCanvas = function (index, left, top, drawingData) {
    let values = JSON.parse(drawingData[index].data);
    let width = drawingData[index].width;
    let height = drawingData[index].height;
    values["left"] = (values["left"] * (props.width || WIDTH)) / width + left;
    values["top"] = (values["top"] * (props.width || WIDTH)) / height + top;
    values["width"] = (values["width"] * (props.width || WIDTH)) / width;
    values["height"] = (values["height"] * (props.width || WIDTH)) / height;
    values["strokeWidth"] =
      (values["strokeWidth"] * (props.width || WIDTH)) / height;

    values.path = values.path.map((val) => {
      for (let i = 1; i < val.length; i++) {
        if (i % 2 == 1) {
          val[i] = (val[i] * (props.width || WIDTH)) / width;
        } else {
          val[i] = (val[i] * (props.width || WIDTH)) / height;
        }
      }
      return val;
    });
    sketch.addObject(
      JSON.stringify({
        ...{ sender: drawingData[index].sender },
        ...values,
      })
    );
  };

  let sketch = useRef(null);

  return (
    <div
      style={{
        width: (props.width || "200px") * props.drawingData.length,
        "padding-top": props.height || "200px",
        position: "relative",
        float: "left",
        "pointer-events": props.editable ? "auto" : "none",
      }}
    >
      <SketchField
        className={`canvas ${!props.borderDisabled ? "blackBorder" : ""}`}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
        }}
        width={(props.width || "200px") * props.drawingData.length}
        height={props.height || "200px"}
        tool={Tools.Pencil}
        lineColor="black"
        lineWidth={3}
        ref={(c) => (sketch = c)}
        username={"kira"}
        shortid={shortid}
        username={props.userId}
        onUpdate={() => {}}
      />
    </div>
  );
}
