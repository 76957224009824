
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from "react";
import { Tooltip } from 'react-tippy';
import shortid from "shortid";
import "../../App.css";
import { AppContext } from "../../AppContext";
import { useStore } from "../../store";
import { PrimaryButton, SecondaryButton } from "../utils/Buttons";
import { CorpseEndCanvas } from "./CorpseEndCanvas";

const useStyles = makeStyles((theme) => ({
  display: {
    padding: 20,
    backgroundColor: "#e8e8e8",
  },

  iconbox: {
    paddingTop: 10,
    display: "flex",
    justifyContent: "space-between"
  },

}))

export const EndScreen = () => {
  const names = ["Ta-da!", "Behold!", "Bravo!", "Hooray!", "Bingo!", "Presto!", "Voila!"];

  const [id] = useState(shortid.generate())
  const classes = useStyles();
  const { height, width } = useContext(AppContext);
  const [uploaded, setUploaded] = useState({});
  const { isMobile } = useContext(AppContext);
  const [selectdPhrase, setSelectedPhrase] = useState(names[Math.floor(Math.random() * names.length)])

  const { setModal, users, setGameProps, roomId, socket, gameProps } = useStore(state => ({
    users: state.users,
    setGameProps: state.setGameProps,
    users: state.users,
    roomId: state.roomId,
    socket: state.socket,
    gameProps: state.gameProps,
    setModal: state.setModal
  }))
  const download = (id) => {
    const canvas = document.getElementById(id).querySelector("canvas");
    const image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
    var link = document.createElement('a');
    link.download = "drawing.png";
    link.href = image;
    link.click();
  }

  const upload = (id, index) => {
    const canvas = document.getElementById(id).querySelector("canvas");
    const image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
    fetch("https://api.cloudinary.com/v1_1/dqzrpmfdk/image/upload", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "upload_preset": "ivx51bzo",
        "file": image
      })
    })
      .then(response => response.json())
      .then(response => {
        socket.emit("email", {
          link: response.secure_url
        })
      })
    let uploadedTemp = { ...uploaded };
    uploadedTemp[index] = true;
    setUploaded(uploadedTemp)
  }


  const startCorpse = () => {
    socket.emit("setGameProps", {
      room: roomId,
      values: {
        participants: Object.keys(users),
        curGame: "corpse",
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        index: 0,
        squiggleEnded: false,
        corpseEnded: false,
        corpseDrawings: new Array(Object.keys(users).length).fill(false).map(x => [[], [], []])
      }
    })
    setGameProps({
      values: {
        corpseEnded: false,
        squiggleEnded: false,
        participants: Object.keys(users),
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        curGame: "corpse",
        index: 0,
        corpseDrawings: new Array(Object.keys(users).length).fill(false).map(x => [[], [], []])
      }
    })
    setModal({
      corpse: -1
    })
  }

  return (
    <div style={{ paddingLeft: !isMobile ? "25%" : "6%", paddingRight: !isMobile ? "25%" : "6%", paddingTop: "4%", position: "relative", zIndex: 100 }}>
      <div style={{ background: "#e8e8e8" }}>
        <br />
        <div>
          <center>
            <Typography variant="subtitle1">
              <b>
                {selectdPhrase}
              </b>
            </Typography>
            <span style={{ float: "right", marginRight: "10px", marginTop: isMobile ? "-32px" : "-35px" }}>
              <PrimaryButton style={{ fontSize: isMobile ? "14px" : "" }} size={isMobile ? "small" : "small"} onClick={startCorpse}>Play again</PrimaryButton>
            </span>
          </center>
        </div>
        <Grid container justify="center" spacing={2} style={{ paddingLeft: isMobile ? "2%" : "8%", paddingRight: isMobile ? "2%" : "8%" }}>
          {gameProps.participants.map((participant, index) => {
            let corpseDrawing = gameProps.corpseDrawings[index];
            return <Grid item xs={6} sm={6} md={6} lg={6}>
              <>
                <CorpseEndCanvas
                  curGame={"squiggle"}
                  userId={id}
                  editable={false}
                  width={"100%"}
                  height={"300%"}
                  drawings={corpseDrawing}
                  id={`canvasDrawing-${index}`}
                />
                <div className={classes.iconbox}>
                  <Tooltip
                    disabled={window.mobileAndTabletCheck()}
                    // options
                    title="Download"
                    position="bottom"
                  >
                    <SecondaryButton size="small" elevation={0} style={{ width: 25, height: 25, cursor: "pointer" }} onClick={() => download(`canvasDrawing-${index}`)}>
                      <img src="/upload.png" height={20} />
                    </SecondaryButton>
                  </Tooltip>

                  <Tooltip
                    disabled={window.mobileAndTabletCheck()}
                    // options
                    title="Submit for display on our site"
                    position="bottom"
                  >
                    <SecondaryButton size="small" disabled={uploaded[index]} elevation={0} style={{ width: 25, height: 25, cursor: "pointer" }} onClick={() => upload(`canvasDrawing-${index}`, index)}>
                      {uploaded[index] ? "Thanks" : <img src="/download.png" height={20} />}
                    </SecondaryButton>
                  </Tooltip>
                </div>
              </>
            </Grid>
          })}
        </Grid>
      </div>
    </div>
  )
}
