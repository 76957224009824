import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { useStore } from '../store';
import { TopLogo } from './TopLogo';
import { PrimaryButton } from './utils/Buttons';
import { CentralizedTextField } from './utils/CentralizedTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e8e8e8",
    minHeight: "100vh",
    width: "100vw"
  },
  button1: {
    marginTop: props => props.isMobile ? 0 :  20,
  },
  button2: {
    marginTop: props => props.isMobile ? 10 :  20,
  },
  box: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 30,
    marginBottom: 5,
    align: "center"
  },
  cardRoot: {
    height: "100%",
    '& > *': {
      borderRadius: 20,
      align: "center",
      padding: props => props.isMobile ? 20 : 40
    },
  },
  centerAdornment: {
    marginLeft: "50%" // or your relevant measure
  },
  centerText: {
    textAlign: "center"
  }
}))


export const LandingPage = () => {
  const { room: urlRoom } = useParams();
  const history = useHistory();
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [room, setRoom] = useState(urlRoom || "");
  const { setName, setRoomId } = useStore(state => ({ setName: state.setName, setRoomId: state.setRoomId }));

  const { isMobile } = useContext(AppContext);

  const { socket, setSocket, setGameStarted, setLoading, setUsers } = useStore(state => ({setUsers: state.setUsers, setLoading:state.setLoading, setGameStarted: state.setGameStarted, socket: state.socket, setSocket: state.setSocket }));

  if(socket) {
    setUsers([])
    setGameStarted(false);
    setLoading(true);
    socket.disconnect();
    setSocket(null);
  }

  const classes = useStyles({ isMobile });
  const generate = () => {
    let ALPHABET = "123456789";
    let ID_LENGTH = 4;
    var rtn = "";
    for (var i = 0; i < ID_LENGTH; i++) {
      rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }
    return rtn;
  }

  const join = () => {
    if (!name2 || name2 === "") {
      return;
    }
    if (!room || room === "") {
      return;
    }
    setName(name2);
    setRoomId(room);
    window.setTimeout(() => {
      history.push("/lobby")
    }, 10);
  }

  const create = () => {
    if (name1 === "") {
      return;
    }
    setName(name1);
    setRoomId(generate());
    window.setTimeout(() => {
      history.push("/prelobby")
    }, 10)
  }

  const setRoomLocal = (e) => {
    const newVal = e.target.value;
    if (newVal.length <= 4 && !isNaN(newVal)) {
      setRoom(e.target.value)
    }
  }

  const getTextSize = () => {
    if (isMobile) {
      return "subtitle1"
    }
    return "h5"
  }

  return (
    <div className={classes.root}>
      {!isMobile && <><br /><br /> </>}
      {isMobile && <br/>}
      <TopLogo />
      {isMobile && <br/>}
      {!isMobile && <br></br>}
      {isMobile && <div style={{marginTop: "-10px"}}></div>}
      <Grid container spacing={isMobile ? 1 : 3} style={{ paddingLeft: isMobile ? 50 : 10, paddingRight: isMobile ? 50 : 10 }}>
        <Grid item xs={0} sm={1} md={1} lg={2} />
        <Grid item xs={12} sm={5} lg={4} md={5} className={classes.cardRoot}>
          <div elevation={0} style={{height: "100%", background: "white"}}>
            <center>
              <Typography variant={getTextSize()} >
                Create a room for
              </Typography>
              <Typography variant={getTextSize()}>
                you and your friends
              </Typography>
              <br />
              <Grid container>
              <Grid item xs={12} lg={12} md={12} style={{ marginBottom: "10px" }}>

              <center>
                <CentralizedTextField
                  style={{ width: isMobile ? "80%" : "90%", maxWidth: "200px" }}
                  placeholder={"Enter your name"}
                  onChange={(e) => setName1(e.target.value)}
                />
                </center>
                </Grid>
              </Grid>
              <div className={classes.button1}>
                <PrimaryButton onClick={create} size="large">
                  Create a room
                </PrimaryButton>
              </div>
            </center>
          </div>
        </Grid>

        <Grid item xs={12} sm={5} md={5} lg={4} className={classes.cardRoot}>
          <div style={{height: "100%", background: "white"}} elevation={0}>
            <center>
              <Typography variant={getTextSize()}>
                Invited by a friend?
              </Typography>
              <Typography variant={getTextSize()}>
                Come on in.
            </Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} md={6} style={{ marginBottom: "1px" }}>
                  <CentralizedTextField
                    style={{ width: isMobile ? "80%" : "95%", maxWidth: isMobile ? "200px" : "" }}
                    placeholder={"Enter 4 digit code"}
                    value={room}
                    onChange={setRoomLocal}
                  />
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <CentralizedTextField
                    style={{ width: isMobile ? "80%" : "90%", maxWidth: isMobile ? "200px" : "" }}
                    placeholder={"Enter your name"}
                    onChange={(e) => setName2(e.target.value)}
                  />
                </Grid>
              </Grid>
              <div className={classes.button2}>
                <PrimaryButton onClick={join} size="large">
                  Enter lobby
                </PrimaryButton>
              </div>
            </center>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.cardRoot}>
            <img src="/downer.png" width={isMobile ? "140px" : "200px"} style={{float: "right"}} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.cardRoot}>
          <br/>
          {!isMobile && <><br/><br/></>}
          <Typography variant={getTextSize()} style={{margin: 0, padding: 0}}>
            <b>
              What's here:
            </b>
          </Typography>
          <Typography variant={isMobile ? "subtitle1" : "h5"} style={{margin: 0, padding: 0}}>
            All sorts of drawing activities {!isMobile && <br/>}  to play with friends.<br/>
            Surprising creatures{isMobile && <br/>} and more!
          </Typography>
        </Grid>

      </Grid>
    </div >
  )
}