//text button text button

// import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Popover } from 'reactstrap';
import { AppContext } from '../AppContext';
import { useStore } from '../store';
import { PrimaryButton } from './utils/Buttons';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: props => props.isMobile ? 0 : 10,
    marginBottom: props => props.isMobile ? 2 : 5,
    
  },
  root2: {
    // height: props => props.isMobile ? 80 : 90,
    // marginTop: props => props.isMobile ? 0 : 10
  },
  button: {
    textTransform: "none",
    fontSize: 17,
    marginLeft: 20,
    marginRight: "20px",
    borderRadius: "20px"
  },
  appbar: {
    backgroundColor: "#e8e8e8",
    color: "black",
    zIndex: 100,
    position: "relative",
    paddingTop: props => props.isMobile ? "2px" : "10px",
    paddingBottom: props => props.isMobile ? "2px" : "10px",
  },
  text: {
    color: "black"
  }
}));

export default function Subhead() {
  
  const { users, setGameProps, roomId, socket, gameProps, setModal, modals } = useStore(state => ({
    users: state.users,
    setGameProps: state.setGameProps,
    users: state.users,
    roomId: state.roomId,
    socket: state.socket,
    gameProps: state.gameProps,
    setModal: state.setModal,
    modals: state.modals
  }))
  const { isMobile } = useContext(AppContext)
  const classes = useStyles({isMobile});
  const [showPopups, setShowPopups] = useState(false);
  const [showPopupsMobile, setShowPopupsMobile] = useState(false);

  const startCorpse = () => {
    socket.emit("setGameProps", {
      room: roomId,
      values: {
        participants: Object.keys(users),
        curGame: "corpse",
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        index: 0,
        squiggleEnded: false,
        corpseEnded: false,
        corpseDrawings: new Array(Object.keys(users).length).fill(false).map(x => [[], [], []])
      }
    })
    setGameProps({
      values: {
        corpseEnded: false,
        squiggleEnded: false,
        participants: Object.keys(users),
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        curGame: "corpse",
        index: 0,
        corpseDrawings: new Array(Object.keys(users).length).fill(false).map(x => [[], [], []])
      }
    })
    setModal({
      corpse: -1
    })
  }
  const startSquiggle = () => {
    socket.emit("setGameProps", {
      room: roomId,
      values: {
        participants: Object.keys(users),
        curGame: "squiggle",
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        index: 0,
        squiggleEnded: false,
        squiggleDrawings: new Array(Object.keys(users).length).fill([[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []])
      }
    })
    setGameProps({
      values: {
        squiggleEnded: false,
        participants: Object.keys(users),
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        curGame: "squiggle",
        index: 0,
        squiggleDrawings: new Array(Object.keys(users).length).fill(false).map(x => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []])
      }
    })
  }

  useEffect(() => {
    window.setTimeout(() => {
      setShowPopups(true);
    }, 300)
  }, [])

  useEffect(() => {
    window.setTimeout(() => {
      if(isMobile) {
        setShowPopups(false);
        setShowPopupsMobile(true);
      } else {
        setShowPopups(true);
        setShowPopupsMobile(false);
      }
      
    }, 300)
  }, [isMobile])
  useEffect(() => {
    if (Object.keys(users).length > 1) {
      if (modals.drawing === 4) {
        setModal({
          drawing: 5
        })
      }
    }
  }, [users])

  useEffect(() => {
    console.log(modals);
    if (modals.drawing === 4 && Object.keys(users).length > 1) {
      setModal({
        drawing: 5
      })
    }
  }, [modals])

  if (isMobile) {
    return <div>
      <div className={classes.appbar}>
        <div className={classes.root2}>
          <Typography className={classes.text} style={{marginLeft: "20px"}} variant="subtitle1">
            When your friends arrive
            </Typography>
          <div className={classes.root}>
            <Tooltip
  disabled={window.mobileAndTabletCheck()}
              // options
              title="Watch a creature emerge!"
              position="bottom"
            >
              <PrimaryButton style={{fontSize: "14px"}} padded={false} onClick={startCorpse} size="small" disabled={Object.keys(users).length === 1} >
                Play Heads & Bodies
              </PrimaryButton>
            </Tooltip>  
            <div id="orText">
              <Typography variant="subtitle1" className={classes.text}>
                or
            </Typography>
          </div>
            <Tooltip
  disabled={window.mobileAndTabletCheck()}
              // options
              title="The drawing and swapping game!"
              position="bottom"
            >

              <PrimaryButton style={{fontSize: "14px"}} size="small" disabled={Object.keys(users).length === 1} onClick={startSquiggle}>
                Play Squiggle
            </PrimaryButton>
            </Tooltip>
          </div>
        </div>
      </div>
      {showPopupsMobile && <Popover placement="bottom" isOpen={gameProps.curGame === "drawing" && modals.drawing === 5} target="orText" toggle={() => {
        setModal({
          drawing: 6
        })
      }}>
        <center>
          <div class="triangle">
          </div>
        </center>
        <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
          <center>
            <Typography variant="subtitle1" >
              When everyone's arrived, <br />
            pick an activity here
          </Typography>
          </center>
        </div>
      </Popover>}
    </div>
  }
  return (
    <div>
      <div className={classes.appbar}>
        <div className={classes.root}>
          <Typography className={classes.text} variant="h6">
            When your friends arrive
            </Typography>
          <Tooltip
            disabled={window.mobileAndTabletCheck()}
            // options
            title="Watch a creature emerge!"
            position="bottom"
          >
            <PrimaryButton padded={true} onClick={startCorpse} size="small" disabled={Object.keys(users).length === 1} >
              Play Heads & Bodies
              </PrimaryButton>
          </Tooltip>
          <div id="orText">
          <Typography variant="h6" className={classes.text}>
            or
          </Typography>
          </div>
          <Tooltip
  disabled={window.mobileAndTabletCheck()}
            // options
            title="The drawing and swapping game!"
            position="bottom"
          >

            <PrimaryButton padded={true} size="small" disabled={Object.keys(users).length === 1} onClick={startSquiggle}>
              Play Squiggle
            </PrimaryButton>
          </Tooltip>

        </div>
      </div>

      {showPopups && <Popover placement="bottom" isOpen={gameProps.curGame === "drawing" && modals.drawing === 5} target="orText" toggle={() => {
        setModal({
          drawing: 6
        })
      }}>
        <center>
          <div class="triangle">
          </div>
        </center>
        <div className="popover_class" style={{ background: "black", color: "white", height: "100%" }}>
          <center>
            <Typography variant="subtitle1" >
              When everyone's arrived, <br />
            pick an activity here
          </Typography>
          </center>
        </div>
      </Popover>}
    </div>
  );
}