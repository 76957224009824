import React, { useEffect, useRef } from "react";
import { SketchField, Tools } from "react-sketch";
import shortid from "shortid";
import "../../App.css";
import { useStore } from "../../store";
const WIDTH = 300;

export const CorpseEndCanvas = function (props) {
  const { size, color } = useStore(store => ({ socket: store.socket, addToGameProp: store.addToGameProp, roomId: store.roomId, refresh: store.refresh, size: store.size, color: store.color }));

  useEffect(() => {
    sketch.clear();
    let height = outerRef.current.clientWidth
    addPrevDrawing(0, 0);
    addPrevDrawing(1, height * 0.9);
    addPrevDrawing(2, height * 1.80);
  }, [props.drawings]);

  const addPrevDrawing = function (index, top) {
    let drawingData = props.drawings[index];
    if (!drawingData) {
      return;
    }
    for (let j = 0; j < drawingData.length; j++) {
      addObjectToCanvas(j, 0, top, drawingData);
    }
  };

  const addObjectToCanvas = function (index, left, top, drawingData) {
    let values = JSON.parse(drawingData[index].data);
    let width = drawingData[index].width;
    let height = drawingData[index].height;
    values["left"] = (values["left"] * outerRef.current.clientWidth) / width + left;
    values["top"] = (values["top"] * outerRef.current.clientWidth) / height + top;
    values["width"] = (values["width"] * outerRef.current.clientWidth) / width;
    values["height"] =
      (values["height"] * outerRef.current.clientWidth) / height;
    values["strokeWidth"] =
      (values["strokeWidth"] * outerRef.current.clientWidth) / height;
    values.path = values.path.map((val) => {
      for (let i = 1; i < val.length; i++) {
        if (i % 2 == 1) {
          val[i] = (val[i] * outerRef.current.clientWidth) / width;
        } else {
          val[i] = (val[i] * outerRef.current.clientWidth) / height;
        }
      }
      return val;
    });
    sketch.addObject(
      JSON.stringify({
        ...{ sender: drawingData[index].sender },
        ...values,
      })
    );
  };

  let sketch = useRef(null);
  let outerRef = useRef(null);

  return (
    <div style={{ background: "white" }}>
      <div
        id={props.id}
        ref={outerRef}
        style={{
          width: props.width || "300px",
          "padding-top": "300%" || "300px",
          position: "relative",
          "pointer-events": props.editable ? "auto" : "none",
        }}
      >
        <SketchField
          className={`canvas ${!props.borderDisabled ? "blackBorder" : ""}`}
          style={{
            background: "white",
            position: "absolute",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
          }}
          id="canvas"
          width={props.width || "300px"}
          height={props.width || "300px"}
          tool={Tools.Pencil}
          lineColor={color || "black"}
          lineWidth={size || 3}
          ref={(c) => (sketch = c)}
          shortid={shortid}
          username={props.userId}
          onUpdate={() => { }}
        />
      </div>
    </div >
  );
}