import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import { Roombox } from './RoomBox';
import { TopLogo } from './TopLogo';
import { AppContext } from '../AppContext';
import { useStore } from '../store';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e8e8e8",
    minHeight: "100vh"
  }
}))


export const RoomBoxTopper = () => {
  const classes = useStyles();
  const { isMobile } = useContext(AppContext);
  const { socket, setSocket, setGameStarted, setLoading, setUsers } = useStore(state => ({setUsers: state.setUsers, setLoading:state.setLoading, setGameStarted: state.setGameStarted, socket: state.socket, setSocket: state.setSocket }));

  if(socket) {
    setUsers([])
    setGameStarted(false);
    setLoading(true);
    socket.disconnect();
    setSocket(null);
  }

  return (
    <div className={classes.root}>
      {!isMobile && <> <br /><br /> </>}
      {isMobile && <br/>}
      <TopLogo />
      <br></br>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Roombox></Roombox></div>
    </div>
  )
} 