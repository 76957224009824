import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import React, { useEffect, useState } from "react";
import { useStore } from '../store';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e8e8e8",
    // borderTop: "1px solid white"
  },
  grid: {
    alignItems: "center"
  },
  icon: {
    background: "white",
    color: "black",
    fontSize: 20
  },
  leftIconContainer: {
    border: "1px solid white",
    height: "100%",
    width: 60,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 0
  },
  iconContainer: {
    border: "1px solid white",
    width: 60,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 0,
    color: "black",
    fontSize: 20
  }
}));

export default function Rpgrid2() {
  const classes = useStyles();
  const { users, socket, mediasoupObj, mediasoupConsumers, gameProps } = useStore(state => ({ users: state.users, socket: state.socket, mediasoupObj: state.mediasoupObj, mediasoupConsumers: state.mediasoupConsumers, gameProps: state.gameProps }))
  const [localUsers, setLocalUsers] = useState({});
  const [audioEnabled, setAudioEnabled] = useState(true);

  useEffect(() => {
    let localUsers2 = {};
    if (!users) {
      return;
    }
    Object.keys(users).map(user => {
      if (typeof localUsers[user] === "boolean") {
        localUsers2[user] = localUsers[user];
      } else {
        localUsers2[user] = false;
      }
    })
    setLocalUsers(localUsers2)
  }, [users])

  const toggleMute = (userId) => {
    let localUsers2 = { ...localUsers };
    localUsers2[userId] = !localUsers2[userId]
    setLocalUsers(localUsers2);
    if (mediasoupConsumers[userId]) {
      if (!localUsers2[userId]) {
        mediasoupConsumers[userId].resume();
      } else {
        mediasoupConsumers[userId].pause();
      }
    }
  }
  const pad = (name) => {
    if (!name) {
      return "";
    }
    if (name.length < 6) {
      return name
    }
    return name.substr(0, 5) + "..."
  }

  const toggleVolumeChatonly = () => {
    if (mediasoupObj && mediasoupObj.audioProducer) {
      if (audioEnabled)
        mediasoupObj.audioProducer.pause();
      else
        mediasoupObj.audioProducer.resume();
      setAudioEnabled(!audioEnabled);
    }
  }

  useEffect(() => {
    if (gameProps.audioDisabled) {
      window.setTimeout(() => {
        setAudioEnabled(false);
        mediasoupObj.audioProducer.pause();
      }, 1000)
    }
  }, [mediasoupObj])

  useEffect(() => {
    if (gameProps.audioDisabled) {
      setAudioEnabled(false);
    }
  }, [gameProps.audioDisabled])

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <div className={classes.leftIconContainer}>
          <IconButton className={classes.icon} aria-label="mic" onClick={toggleVolumeChatonly} style={{ color: "black" }}>
            {audioEnabled ? <MicIcon style={{ fontSize: 40, }} /> : <MicOffIcon style={{ fontSize: 40, }} />}
          </IconButton>
          <center>
            <div style={{ marginBottom: "18px", marginTop: "2px" }}>
              <Typography variant={"subtitle1"}>
                <b>
                  You
                                  </b>
              </Typography>
            </div>
          </center>
        </div>
        {users && Object.keys(users).filter(user => user !== socket.id).map(user => <div>
          <div className={classes.iconContainer}>
            <IconButton style={{ color: "black" }} aria-label="mic" onClick={() => toggleMute(user)}>
              {!localUsers[user] ? <MicIcon style={{ fontSize: 40, }} /> : <MicOffIcon style={{ fontSize: 40, }} />}
            </IconButton>
            <center>
              <div style={{ marginBottom: "20px" }}>
                <Typography variant={"subtitle1"}>
                  <b>
                    {pad(JSON.parse(users[user]).name)}
                  </b>
                </Typography>
              </div>
            </center>
          </div>
        </div>
        )}

      </div>
    </div>


  )
}

