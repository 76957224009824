let localMediasoup;
/*
 * Mediasoup producer and consumer handler class
 * This class handles the creation and handling of mediasoup 
 * producers and consumers
 */
export default class {
  constructor(url, room, name) {
    const urlParams = new URLSearchParams(window.location.search)
    localMediasoup = this;
    localMediasoup.stream = undefined
    localMediasoup.consumerTransport = undefined
    localMediasoup.producerTransport = undefined
    localMediasoup.signalTransport = undefined
    localMediasoup.peerVideoHandler = undefined
    localMediasoup.peerAudioHandler = undefined
    localMediasoup.peerDisconnectHandler = () => {}
    localMediasoup.audioProducer = undefined
    localMediasoup.audioProducerId = undefined
    localMediasoup.room = room
    localMediasoup.name = name


    //Added for additional avatar data channels
    localMediasoup.movementProducer = undefined
    localMediasoup.childrenProducer = undefined
    localMediasoup.peerMovementHandler = undefined
    localMediasoup.peerHandsHandler = undefined
    localMediasoup.peerChildrenHandler = undefined
    localMediasoup.movementProducerId = undefined
    localMediasoup.childrenProducerId = undefined

    localMediasoup.signalTransport = new WebSocket(url);
    localMediasoup.signalTransport.onerror = function (err) { }
    localMediasoup.signalTransport.onclose = () => { console.log("Closed") }
    localMediasoup.signalTransport.onmessage = this.incomingSignal;
    localMediasoup.signalTransport.onopen = () => { localMediasoup.request('requestIdentity', { room: localMediasoup.room, name: localMediasoup.name }) }
    localMediasoup.request = function (type, message) {
      localMediasoup.signalTransport.send(JSON.stringify({ type: type, message: message }))
    }

    try {
      localMediasoup.webRTCDevice = new window.mediasoup.Device()
    } catch (err) {
      console.log(err);
      //alert("Failed to load WebRTCDevice");
    }
  }

  async init(func) {
    if (localMediasoup.stream !== undefined) {
      return false;
    }
    func();

    const mediaOptions = { video: false, audio: true }
    try {
      localMediasoup.webRTCDevice.canProduce('audio')
    } catch (err) {
      console.log(err);
      //alert('Local hardware cannot produce required Audio. Please check microphone sensors.');
      mediaOptions.audio = false;
    }

    navigator.mediaDevices.getUserMedia(mediaOptions)
      .then((stream) => {
        localMediasoup.stream = stream;
        localMediasoup.request('createTransports', {
          peerId: localMediasoup.avatar_id,
          numStreams: localMediasoup.webRTCDevice.sctpCapabilities.numStreams,
          rtpCapabilities: localMediasoup.webRTCDevice.rtpCapabilities
        });
      })
      .catch((err) => { alert(err) });
  }

  mute(mute) {
    localMediasoup.request('mutePeer', { peerId: localMediasoup.avatar_id, mute: mute });
  }

  setAudioHandler(func) {
    localMediasoup.peerAudioHandler = func
  }

  setMovementHandler(func) {
    localMediasoup.peerMovementHandler = func
  }
  setHandsHandler(func) {
    localMediasoup.peerHandsHandler = func
  }
  setChildrenHandler(func) {
    localMediasoup.peerChildrenHandler = func
  }

  setPeerDisconnectHandler(func) {
    localMediasoup.peerDisconnectHandler = func
  }

  setPeerMuteHandler(func) {
    localMediasoup.peerMuteHandler = func
  }

  async incomingSignal(message) {
    const signal = JSON.parse(message.data)

    if (signal.type === "identity") {

      localMediasoup.avatar_id = signal.data.id
      await localMediasoup.webRTCDevice.load({ routerRtpCapabilities: signal.data.roomRTPCapabilities });
    } else if (signal.type === "createdTransports") {
      localMediasoup.connectTransports(signal.data);
    } else if (signal.type === "connectedTransport") {

    } else if (signal.type === "consumeAudioAnnouncement") {
      for (const producerPeer in signal.data) {
        await localMediasoup.consumerTransport.consume(signal.data[producerPeer])
          .then((newConsumer) => {
            localMediasoup.peerAudioHandler(newConsumer.track, producerPeer, newConsumer)
          })
      };
    } else if (signal.type === "producedAudio") {
      localMediasoup.audioProducerId = signal.data.id;
      localMediasoup.request('consumeAllAudio', { requestingPeer: localMediasoup.avatar_id })
    } else if (signal.type === "peerDisconnect") {
      localMediasoup.peerDisconnectHandler(signal.data.peer);
    } else if (signal.type === "peerMute") {
      localMediasoup.peerMuteHandler(signal.data.peerId, signal.data.mute)
    }
  }

  async connectTransports(transports) {
    localMediasoup.producerTransport = await localMediasoup.webRTCDevice.createSendTransport(
      {
        id: transports.producer.id,
        iceParameters: transports.producer.iceParameters,
        iceCandidates: transports.producer.iceCandidates,
        dtlsParameters: transports.producer.dtlsParameters,
        sctpParameters: transports.producer.sctpParameters
      });
    localMediasoup.consumerTransport = await localMediasoup.webRTCDevice.createRecvTransport(
      {
        id: transports.consumer.id,
        iceParameters: transports.consumer.iceParameters,
        iceCandidates: transports.consumer.iceCandidates,
        dtlsParameters: transports.consumer.dtlsParameters,
        sctpParameters: transports.consumer.sctpParameters
      });

    localMediasoup.consumerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
      localMediasoup.request('connectTransport', { peerId: localMediasoup.avatar_id, direction: localMediasoup.consumerTransport.direction, dtlsParameters: dtlsParameters })
      callback();
    })

    localMediasoup.producerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
      localMediasoup.request('connectTransport', { peerId: localMediasoup.avatar_id, direction: localMediasoup.producerTransport.direction, dtlsParameters: dtlsParameters })
      callback();
    })

    localMediasoup.producerTransport.on('produce', async function ({ kind, rtpParameters, appData }, callback, errback) {
      //Send producer data to server
      localMediasoup.request('produceMedia', {
        producingPeer: localMediasoup.avatar_id,
        producerOptions: {
          kind: kind,
          rtpParameters: rtpParameters,
          appData: appData
        }
      })
      if (kind === "video") {
      } else {
        await localMediasoup.waitFor(_ => localMediasoup.audioProducerId !== undefined)
        callback({ id: localMediasoup.audioProducerId });
      }
    })

    localMediasoup.localAudioTrack = localMediasoup.stream.getAudioTracks()[0];
    localMediasoup.audioProducer = await localMediasoup.producerTransport.produce({ track: localMediasoup.localAudioTrack });
  }
  waitFor(conditionFunction) {
    const poll = resolve => {
      if (conditionFunction()) resolve();
      else setTimeout(_ => poll(resolve), 400);
    }
    return new Promise(poll);
  }
}
