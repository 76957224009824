import { Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { useStore } from '../store'
import { PrimaryButton } from './utils/Buttons'
import { AppContext } from '../AppContext'

export const LeftEarly = () => {
  const {isMobile} = useContext(AppContext)
  const { users, setGameProps, roomId, socket, gameProps } = useStore(state => ({
    users: state.users,
    setGameProps: state.setGameProps,
    users: state.users,
    roomId: state.roomId,
    socket: state.socket,
    gameProps: state.gameProps
  }))
  const startDrawing = () => {
    socket.emit("setGameProps", {
      room: roomId,
      values: {
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        curGame: "drawing",
        index: 0,
        squiggleEnded: false,
      }
    })
    setGameProps({
      values: {
        doneDrawings: new Array(Object.keys(users).length).fill(false),
        curGame: "drawing",
        index: 0,
        squiggleEnded: false,
      }
    })
  }
  return (
    <div> <br /><br /><br /> {!isMobile && <> <br /><br /><br /> </>}
      <center>
        <div style={{padding: "10px", borderRadius: "10px", boxShadow: "2px black", width: isMobile ? "65%" : "55%", background: "white", position: "relative", zIndex: 1000, background: "black", color: "white" }}>
          <br /><br /><br />
          {!isMobile && <> <br /><br /><br /> </>}
          <Typography variant={ isMobile ? "h6":"h5"}>
            Yipes! Someone left the room so you’ll need to restart this activity in the lobby.
          </Typography>
          <br />
          <center>
            <PrimaryButton
              onClick={startDrawing}
              size="small"
              style={{fontSize: isMobile ? "14px" : ""}} 
            > Back to Lobby </PrimaryButton>
          </center>
          <br /><br />

          {!isMobile && <> <br /><br /><br /> </>}
        </div>
      </center>
    </div >
  )
}
