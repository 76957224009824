import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStore } from '../store';
import { GamePage } from './GamePage';

export const GamePageTopper = (props) => {
  const { name, setName, setRoomId, roomId, socket,setSocket } = useStore(state => ({ name: state.name, setName: state.setName, setRoomId: state.setRoomId, roomId: state.roomId, socket: state.socket,setSocket: state.setSocket }));

  const history = useHistory();
  const { admin } = useParams();

  if (!name || !roomId || name === "" || roomId === "")
    history.push("/");

  return (
    <GamePage isAdmin={admin} />
  )
}
