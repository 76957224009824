import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { MessageList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { useStore } from '../store';
import Ads from './utils/Ads';
import { CentralizedTextField } from './utils/CentralizedTextField';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 200,
        borderRadius: 20,
        background: "white",
        marginBottom: 10,
        overflowY: "scroll",
    },
    subRoot: {

    },
    chat: {
        '& > *': {
            backgroundColor: "white",
        },
    },
    section: {
        padding: theme.spacing(3),
        backgroundColor: "#e8e8e8",
        borderBottom: "1px solid white"
    }
}));

export default function Rpgrid3() {

    const classes = useStyles();
    const { socket, roomId, messages, addMessage } = useStore(state => ({ socket: state.socket, roomId: state.roomId, messages: state.messages, addMessage: state.addMessage }));
    const [msg, setMsg] = useState("");

    useEffect(() => {
        document.onkeypress = (e) => {
            if (e.which == 13) {
                send();
            }
        };
    }, [])


    useEffect(() => {
        var objDiv = document.getElementById("msg");
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }, [messages]);

    const send = () => {
        socket.emit("chat", {
            room: roomId,
            user: socket.id,
            message: document.getElementById("chatinp").value
        });
        setMsg("")
    }
    const getMessages = () => {
        const dataSources = messages.map((message) => {
            return {
                position: socket.id == message.user ? "right" : "left",
                type: "text",
                text: message.message,
                date: null,
            };
        });
        return (
            <MessageList
                className="message-list"
                lockable={true}
                toBottomHeight={"100%"}
                dataSource={dataSources}
            />
        );
    };


    return (
        <div className={classes.section}>
            <div className={classes.root} style={{ height: 200 }}>
                <div id="msg">
                    {getMessages()}
                </div>
            </div>
            <center>
                <div className={classes.subRoot}>
                    <CentralizedTextField id="chatinp" value={msg} onChange={(e) => setMsg(e.target.value)} placeholder="Chat here" style={{ width: "90%" }} />
                </div>
            </center>
            <br />
            <Ads />
        </div>
    )
}

