import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GamePageTopper } from './components/GamePageTopper';
import { LandingPage } from './components/LandingPage';
import { RoomBoxTopper } from './components/RoomBoxTopper';
import ScrollToTop from './components/utils/ScrollToTop';

export default function App() {
  return (
    <Router>

     <Fragment>
      <ScrollToTop />
      <Switch>
        <Route exact path='/lobby' component={GamePageTopper}></Route>
        <Route exact path='/lobby/:admin' component={GamePageTopper}></Route>
        <Route exact path='/prelobby' component={RoomBoxTopper}></Route>
        <Route exact path='/join/:room' component={LandingPage}></Route>
        <Route exact path='/' component={LandingPage}></Route>
        </Switch>
      </Fragment>
    </Router>
  )
}


