import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import Rpgrid1 from "./Rpgrid1";
import Rpgrid2 from "./Rpgrid2";
import Rpgrid3 from "./Rpgrid3";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 16
    }
}));


export default function Rightpanel() {
    const classes = useStyles();

    return (

        <div className={classes.root}>
            <div>
                <Rpgrid1></Rpgrid1>
            </div>


            <div>
                <Rpgrid2></Rpgrid2>
            </div>

            <div>
                <Rpgrid3></Rpgrid3>
            </div>

        </div>


    )
}

