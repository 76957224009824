import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import openSocket from "socket.io-client";
import { backendUrl } from "../config";
import { useStore } from '../store';

export default function useSocket({ isAdmin }) {
  const { socket, setSocket, defaultAudio, setGameProps, addToGameProp, setUsers, name, roomId, addMessage, setGameStarted } = useStore(state => state)

  const history = useHistory();

  useEffect(() => {
    if (!roomId) {
      return;
    }

    const socket = openSocket(`${backendUrl}`, { transport: ['websocket'] });
    setSocket(socket);

    socket.emit("join", {
      name,
      room: roomId,
      audioDisabled: !defaultAudio,
      isAdmin
    })

    socket.on("roomDoesntExist", () => {
      history.push("/")
    })

    socket.on("addToGameProp", (props) => {
      addToGameProp(props);
    })

    socket.on("updateGameProp", (props) => {
      setGameStarted(true);
      setGameProps(props);
    })

    socket.on("set-users", (props) => {
      setUsers(props.users);
    })

    socket.on("chat", (props) => {
      addMessage(props);
    })

  }, [setSocket, roomId])

  return socket;
}